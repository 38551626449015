import { faMagnifyingGlass } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";

interface SearchAthletesProps {
  value: string;
  handleChange: (e: any) => void;
}

const SearchAthletes = ({ value, handleChange }: SearchAthletesProps) => {
  return (
    <div className="rounded-lg ">
      <div className="flex flex-col justify-between h-full rounded-lg p-[.5px]">
        <label className="relative block rounded-lg">
          <span className="sr-only">Search</span>
          <span className="absolute inset-y-0 left-0 flex items-center pl-2">
            <FontAwesomeIcon
              className="text-text-nonfocus"
              icon={faMagnifyingGlass}
            />
          </span>
          <input
            type="text"
            name="search"
            placeholder="Search athletes"
            className=" bg-back-light font-medium  h-8 pl-9 focus:outline-none text-slate-dark rounded-md placeholder:text-text-nonfocus focus:bg-light-focus hover:bg-light-focus"
            value={value}
            onChange={handleChange}
          />
        </label>
      </div>
    </div>
  );
};

export default SearchAthletes;
