import { ComponentType, Suspense, Component as ReactComponent } from "react";
import { Routes, Route } from "react-router-dom";
import { Helmet } from "react-helmet";
import Spinner from "./components/loading/Spinner";

/***** Route Helpers *****/
import PublicLayout from "./layouts/PublicLayout";
import PrivateRoute from "./auth/PrivateRoute";
import PublicRoute from "./auth/PublicRoute";
import TokenGuard from "./contexts/TokenGuardContext";

/***** Public Pages *****/
import Login from "src/pages/public/Login";
import NotFound from "src/pages/public/NotFound";

/***** Private Pages *****/
import Athletes from "./pages/private/Athletes";
import Athlete from "./pages/private/Athlete";
import AthletesImport from "./pages/private/AthletesImport";
import AthletesImportNames from "./pages/private/AthletesImportNames";
import Admin from "./pages/private/Admin";
import Trends from "./pages/private/Trends";
import Register from "./pages/public/Register";
import PasswordReset from "./pages/public/PasswordReset";
import PasswordResetToken from "./pages/public/PasswordResetToken";
import InvalidToken from "./pages/public/InvalidToken";
import Forbidden from "./pages/public/Forbidden";

export const Loader = <P extends object>(Component: ComponentType<P>) =>
  class WithLoader extends ReactComponent<P> {
    render() {
      const { ...props } = this.props;
      return (
        <Suspense fallback={<Spinner />}>
          <Component {...(props as P)} />
        </Suspense>
      );
    }
  };

const Router = () => {
  return (
    <>
      {/*** Public Routes ***/}
      <Routes>
        <Route
          path="/"
          element={
            <PublicRoute>
              <Helmet>
                <title>Login | Genesis Training Academy</title>
              </Helmet>
              <Login />
            </PublicRoute>
          }
        />

        <Route
          path="/login"
          element={
            <PublicRoute>
              <Helmet>
                <title>Login | Genesis Training Academy</title>
              </Helmet>
              <Login />
            </PublicRoute>
          }
        />

        <Route
          path="/register/:tokenValue"
          element={
            <TokenGuard>
              <PublicRoute>
                <Helmet>
                  <title>Register | Genesis Training Academy</title>
                </Helmet>
              </PublicRoute>
            </TokenGuard>
          }
        />

        <Route
          path="/register"
          element={
            <TokenGuard>
              <PublicRoute>
                <Helmet>
                  <title>Register | Genesis Training Academy</title>
                </Helmet>
                <Register />
              </PublicRoute>
            </TokenGuard>
          }
        />

        <Route
          path="/password-reset"
          element={
            <TokenGuard>
              <PublicRoute>
                <Helmet>
                  <title>Password Reset | Genesis Training Academy</title>
                </Helmet>
                <PasswordReset />
              </PublicRoute>
            </TokenGuard>
          }
        />

        <Route
          path="/password-reset/:tokenValue"
          element={
            <TokenGuard>
              <PublicRoute>
                <Helmet>
                  <title>Password Reset | Genesis Training Academy</title>
                </Helmet>
              </PublicRoute>
            </TokenGuard>
          }
        />

        <Route
          path="/reset-password"
          element={
            <TokenGuard>
              <PublicRoute>
                <Helmet>
                  <title>Password Reset | Genesis Training Academy</title>
                </Helmet>
                <PasswordResetToken />
              </PublicRoute>
            </TokenGuard>
          }
        />

        <Route
          path="/invalid-token"
          element={
            <TokenGuard>
              <PublicRoute>
                <Helmet>
                  <title>Invalid Token | Genesis Training Academy</title>
                </Helmet>
                <InvalidToken />
              </PublicRoute>
            </TokenGuard>
          }
        />

       

        {/*** Private Routes ***/}
        <Route
          path="/athletes"
          element={
            <PrivateRoute>
              <Helmet>
                <title>Athletes | Genesis Training Academy</title>
              </Helmet>
              <Athletes />
            </PrivateRoute>
          }
        />

        <Route
          path="/athletes/:athleteID"
          element={
            <PrivateRoute>
              <Helmet>
                <title>Athlete | Genesis Training Academy</title>
              </Helmet>
              <Athlete />
            </PrivateRoute>
          }
        />

        <Route
          path="/athletes/edit-import"
          element={
            <PrivateRoute>
              <Helmet>
                <title>Edit Import | Genesis Training Academy</title>
              </Helmet>
              <AthletesImport />
            </PrivateRoute>
          }
        />

        <Route
          path="/athletes/edit-import-names"
          element={
            <PrivateRoute>
              <Helmet>
                <title>Edit Import | Genesis Training Academy</title>
              </Helmet>
              <AthletesImportNames />
            </PrivateRoute>
          }
        />

        <Route
          path="/admin"
          element={
            <PrivateRoute>
              <Helmet>
                <title>Admin | Genesis Training Academy</title>
              </Helmet>
              <Admin />
            </PrivateRoute>
          }
        />

        <Route
          path="/trends"
          element={
            <PrivateRoute>
              <Helmet>
                <title>Trends | Genesis Training Academy</title>
              </Helmet>
              <Trends />
            </PrivateRoute>
          }
        />

        {/*** 403 Forbidden Handler ***/}

        <Route
          path="/403"
          element={
            <PublicLayout>
              <Helmet>
                <title>403 Forbidden | Genesis Training Academy</title>
              </Helmet>
              <Forbidden />
            </PublicLayout>
          }
        />

        {/*** 404 Not Found Handler ***/}

        <Route
          path="*"
          element={
            <PublicLayout>
              <Helmet>
                <title>404 Not Found | Genesis Training Academy</title>
              </Helmet>
              <NotFound />
            </PublicLayout>
          }
        />
      </Routes>
    </>
  );
};

export default Loader(Router);
