import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ButtonLoader from "../loading/ButtonLoader";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { motion } from "framer-motion";

interface ModalButtonProps {
  isLoading: boolean;
  onSubmit: () => void;
  label: string;
  loadingLabel: string;
  labelColor?: string;
  icon: IconProp;
  loaderColor?: string;
  loaderBackgroundColor?: string;
}

const ModalButton = ({
  isLoading,
  onSubmit,
  label,
  loadingLabel,
  labelColor,
  icon,
  loaderColor,
  loaderBackgroundColor,
}: ModalButtonProps) => {
  return (
    <>
      <motion.button
        layout
        transition={{ duration: 0.2 }}
        style={{color: labelColor}}
        className="btn btn-ghost hover:bg-gray-200 normal-case text-lg mt-0.5"
        onClick={onSubmit}
      >
        {isLoading ? (
          <motion.div
            className="flex gap-2"
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.5 }}
          >
            <ButtonLoader
              foregroundColor={loaderColor}
              backgroundColor={loaderBackgroundColor}
            />
            <p>{loadingLabel}</p>
          </motion.div>
        ) : (
          <motion.div
            className="flex gap-2"
            initial={{ opacity: 0, scale: 0.8 }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0, scale: 0.5 }}
          >
            <FontAwesomeIcon icon={icon} />
            <p>{label}</p>
          </motion.div>
        )}
      </motion.button>
    </>
  );
};

export default ModalButton;
