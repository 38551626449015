import { useEffect } from "react";
import { Navigate, useLocation } from "react-router-dom";
import useAuth from "src/hooks/useAuth";
import PrivateProvider from 'src/provider/private';
import PrivateLayout from "src/layouts/PrivateLayout";

/* Private Route that checks if the user is logged in then provides correct Layout depending on Subscription Level */

interface PrivateRouteProps {
  children: JSX.Element | JSX.Element[];
}

const PrivateRoute = ({ children }: PrivateRouteProps) => {
  const { currentUser, setRedirection } = useAuth();
  const location = useLocation();

  useEffect(() => {
    setRedirection(location.pathname);
  }, [location.pathname, setRedirection]);

  if (!currentUser) {
    return <Navigate to="/" />;
  }

  return (
    <>
      <PrivateProvider>
        <PrivateLayout>{children}</PrivateLayout>
      </PrivateProvider>
    </>
  );
};

export default PrivateRoute;
