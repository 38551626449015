import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { gymApi, userApi } from "src/api";
import LocationActionsButton from "src/components/buttons/LocationActionsButton";
import LocationButton from "src/components/buttons/LocationButton";
import TrainerActionsButton from "src/components/buttons/TrainerActionsButton";
import GymRow from "src/components/gym/GymRow";
import LoadingTable from "src/components/loading/LoadingTable";
import capitalize from "src/helpers/capitalize";
import useUserContext from "src/hooks/private/useUserContext";
import { GymTable } from "src/interfaces/gym.interface";
import { Trainer } from "src/interfaces/user.interface";

const Admin = () => {
  const { user, fetchGym } = useUserContext();

  const navigate = useNavigate();

  useEffect(() => {
    if (user?.role === "trainer") {
      return navigate("/");
    }
  }, [navigate, user?.role]);

  const [gyms, setGyms] = useState<GymTable[]>([]);

  const [trainers, setTrainers] = useState<Trainer[]>([]);

  const [isLoadingGyms, setIsLoadingGyms] = useState<boolean>(true);

  const [isLoadingTrainers, setIsLoadingTrainers] = useState<boolean>(true);

  const fetchGyms = async () => {
    try {
      const gyms = await gymApi.getAll();
      setGyms(gyms);
    } catch (err: any) {
      console.error(err);
    } finally {
      setIsLoadingGyms(false);
    }
  };

  const fetchTrainers = async () => {
    try {
      const trainers = await userApi.getTrainers();
      setTrainers(trainers);
    } catch (err: any) {
      console.error(err);
    } finally {
      setIsLoadingTrainers(false);
    }
  };

  useEffect(() => {
    fetchGyms();
    fetchTrainers();
  }, []);

  const getGymNameFromID = (gymID: number) => {
    return gyms.find((gym: GymTable) => gymID === gym.id)?.name;
  };

  useEffect(() => {
    return () => fetchGym();
  }, [fetchGym]);

  return (
    <div className="">
      <div className="mt-4">
        <div className="w-full flex justify-between items-center">
          <div className="text-lg font-bold">Locations</div>
          {user?.role === "owner" && (
            <div>
              <LocationButton fetchGyms={fetchGyms} />
            </div>
          )}
        </div>
        {isLoadingGyms ? (
          <LoadingTable label={"Locations"} />
        ) : (
          <div className="">
            <table className="table w-full min-w-[600px]">
              <thead>
                <tr className="border-b-2 bg-back-light">
                  <th className=" bg-back-light border-b-2">#ID</th>
                  <th className=" bg-back-light border-b-2">Name</th>
                  <th className=" bg-back-light border-b-2">Athletes</th>
                  <th className=" bg-back-light border-b-2">Trainers</th>
                  <th className=" bg-back-light border-b-2">Power</th>
                  <th className=" bg-back-light border-b-2">Strength</th>
                  <th className=" bg-back-light border-b-2">Agility</th>
                  <th className=" bg-back-light border-b-2">Speed</th>
                  <th className=" bg-back-light border-b-2">Actions</th>
                </tr>
              </thead>
              <tbody className="border-b-2">
                {gyms.map((gym: GymTable, index: number) => (
                  <GymRow
                    key={`gyms-${index}`}
                    gym={gym}
                    fetchGyms={fetchGyms}
                    fetchTrainers={fetchTrainers}
                  />
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
      <div className="mt-8">
        <div className="w-full flex justify-between items-center">
          <div className="text-lg font-bold">Trainers</div>
        </div>
        {isLoadingTrainers ? (
          <LoadingTable label={"Trainers"} />
        ) : (
          <div className="">
            <table className="table w-full min-w-[600px]">
              <thead>
                <tr className="border-b-2 bg-back-light">
                  <th className=" bg-back-light border-b-2">#ID</th>
                  <th className=" bg-back-light border-b-2">Name</th>
                  <th className=" bg-back-light border-b-2">Email</th>
                  {user?.role === "owner" && (
                    <>
                      <th className=" bg-back-light border-b-2">Location</th>
                      <th className=" bg-back-light border-b-2">Role</th>
                    </>
                  )}
                  <th className=" bg-back-light border-b-2">Actions</th>
                </tr>
              </thead>
              <tbody className="border-b-2">
                {trainers.map((trainer: Trainer, index: number) => (
                  <tr key={`trainers-${index}`}>
                    <td>{trainer.id}</td>
                    <td>{trainer.name}</td>
                    <td>{trainer.email}</td>
                    {user?.role === "owner" && (
                      <>
                        <td>{getGymNameFromID(trainer.gym_id)}</td>
                        <td>{capitalize(trainer.role)}</td>
                      </>
                    )}
                    <td>
                      <TrainerActionsButton
                        trainer={trainer}
                        fetchGyms={fetchGyms}
                        fetchTrainers={fetchTrainers}
                      />
                    </td>
                  </tr>
                ))}
              </tbody>
            </table>
          </div>
        )}
      </div>
    </div>
  );
};

export default Admin;
