import axios from "src/utils/axios";
import ApiTokenSupport from "./token.support";
import MetricType from "src/interfaces/metricType.interface";

class MetricTypeApi extends ApiTokenSupport {
  // Get all metric types
  async getAll(): Promise<MetricType[]> {
    const resp = await axios.get<MetricType[]>(
      `api/metric-type`,
      this.withAuthorization()
    );
    return resp.data;
  }

  // Get a specific metric type by ID
  async getByID(metricTypeID: number): Promise<MetricType> {
    const resp = await axios.get<MetricType>(
      `api/metric-type/${metricTypeID}`,
      this.withAuthorization()
    );
    return resp.data;
  }

  // Create a new metric type
  async create(data: MetricType): Promise<MetricType> {
    const resp = await axios.post<MetricType>(
      `api/metric-type`,
      data,
      this.withAuthorization()
    );
    return resp.data;
  }

  // Update a metric type by ID
  async update(
    metricTypeID: number,
    data: Partial<MetricType>
  ): Promise<MetricType> {
    const resp = await axios.put<MetricType>(
      `api/metric-type/${metricTypeID}`,
      data,
      this.withAuthorization()
    );
    return resp.data;
  }

  // Delete a metric type by ID
  async delete(metricTypeID: number): Promise<MetricType> {
    const resp = await axios.delete<MetricType>(
      `api/metric-type/${metricTypeID}`,
      this.withAuthorization()
    );
    return resp.data;
  }
}

export default new MetricTypeApi();
