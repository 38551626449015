

const Spinner = () => {
  return (
    <div className="spinner">
      <div className="spinner__icon">
        
      </div>
    </div>
  );
}

export default Spinner;
