import { PropsWithChildren } from "react";
import { BrowserRouter } from "react-router-dom";

/***** Context Provider *****/
import { ThemeProvider } from "src/contexts/ThemeContext";
import { AuthProvider } from "src/contexts/AuthContext";
import { AlertProvider } from "src/contexts/AlertContext";

interface AppProviderProps {}

const AppProvider = ({ children }: PropsWithChildren<AppProviderProps>) => (
  <ThemeProvider>
    <AuthProvider>
      <BrowserRouter>
        <AlertProvider>
            <AuthProvider>{children}</AuthProvider>
        </AlertProvider>
      </BrowserRouter>
    </AuthProvider>
  </ThemeProvider>
);

export default AppProvider;
