import axios from "src/utils/axios";
import ApiTokenSupport from "./token.support";
import Gym, { GymTable } from "src/interfaces/gym.interface";
import GymMetricPreference from "src/interfaces/gymMetricPreference.interface";

class GymApi extends ApiTokenSupport {
  async getAll(): Promise<GymTable[]> {
    const resp = await axios.get<GymTable[]>(
      `api/gym`,
      this.withAuthorization()
    );
    return resp.data;
  }

  async get(id: number): Promise<Gym> {
    const resp = await axios.get<Gym>(
      `api/gym/${id}`,
      this.withAuthorization()
    );
    return resp.data;
  }

  async create(name: string): Promise<Gym> {
    const resp = await axios.post<Gym>(
      `api/gym`,
      { name },
      this.withAuthorization()
    );
    return resp.data;
  }

  async update(data: { id: number; key: string; value: any }): Promise<Gym> {
    const resp = await axios.put<Gym>(
      `api/gym/${data.id}`,
      data,
      this.withAuthorization()
    );
    return resp.data;
  }

  async getAllGymMetricPreferences(): Promise<GymMetricPreference[]> {
    const resp = await axios.get<GymMetricPreference[]>(
      `api/gym-metric-preference`,
      this.withAuthorization()
    );
    return resp.data;
  }

  async getMetricPreferences(gymID: number): Promise<GymMetricPreference[]> {
    const resp = await axios.get<GymMetricPreference[]>(
      `api/gym-metric-preference/gym/${gymID}`,
      this.withAuthorization()
    );
    return resp.data;
  }

  async updateMetricPreference(
    preferenceID: number,
    data: Partial<GymMetricPreference>
  ): Promise<GymMetricPreference> {
    const resp = await axios.put<GymMetricPreference>(
      `api/gym-metric-preference/${preferenceID}`,
      data,
      this.withAuthorization()
    );
    return resp.data;
  }
}

export default new GymApi();
