import { faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useState } from "react";
import { motion } from "framer-motion";

interface ImportNameRowProps {
  athlete: { Name: string };
  onUpdate: (updatedAthlete: { Name: string }) => void;
  onDelete: () => void;
}

const ImportNameRow = ({ athlete, onUpdate, onDelete }: ImportNameRowProps) => {
  const [editMode, setEditMode] = useState(false);
  const [name, setName] = useState(athlete.Name);

  const handleUpdate = () => {
    onUpdate({ Name: name });
    setEditMode(false);
  };

  const handleBlur = () => {
    handleUpdate();
  };

  const handleKeyDown = (e: React.KeyboardEvent) => {
    if (e.key === "Enter") {
      handleUpdate();
    }
  };

  const [isHoveringRow, setIsHoveringRow] = useState<boolean>(false);

  return (
    <motion.tr
      className="cursor-pointer border-b-1"
      initial={{ opacity: 0 }}
      animate={{ opacity: 1 }}
      transition={{
        opacity: { duration: 0.25 },
      }}
      exit={{ opacity: 0 }}
      onHoverStart={() => setIsHoveringRow(true)}
      onHoverEnd={() => setIsHoveringRow(false)}
    >
      <td className="py-0 my-0">
        <button
          className={
            isHoveringRow
              ? "btn btn-square btn-ghost h-6 w-6 px-6 text-black hover:text-error"
              : "invisible h-6 w-6 px-6"
          }
          onClick={onDelete}
        >
          <FontAwesomeIcon
            icon={faTrashCan}
            className={isHoveringRow ? "" : "invisible"}
          />
        </button>
      </td>
      <td onClick={() => setEditMode(true)}>
        {editMode ? (
          <input
            type="text"
            value={name}
            onChange={(e) => setName(e.target.value)}
            onBlur={handleBlur}
            onKeyDown={handleKeyDown}
            autoFocus
          />
        ) : (
          <span>{athlete.Name}</span>
        )}
      </td>
    </motion.tr>
  );
};

export default ImportNameRow;
