import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useState,
} from "react";
import useAuth from "src/hooks/useAuth";
import userApi from "src/api/user";

import useAlert from "src/hooks/useAlert";
import User from "src/interfaces/user.interface";
import Gym from "src/interfaces/gym.interface";
import { gymApi, metricTypeApi } from "src/api";
import MetricType from "src/interfaces/metricType.interface";
import GymMetricPreference from "src/interfaces/gymMetricPreference.interface";

interface UserContextInterface {
  user: User | null | undefined;
  fetchUser: () => void;
  isLoadingUser: boolean;
  users: any[] | null | undefined;
  fetchUsers: () => void;
  gym: Gym | null | undefined;
  fetchGym: () => void;
  metricTypes: MetricType[] | null | undefined;
  fetchMetricTypes: () => void;
  gymMetricPreferences: GymMetricPreference[] | null | undefined;
  fetchGymMetricPreferences: () => void;
}

const UserContextDefaults: UserContextInterface = {
  user: null,
  fetchUser: () => {},
  isLoadingUser: false,
  users: null,
  fetchUsers: () => {},
  gym: null,
  fetchGym: () => {},
  metricTypes: null,
  fetchMetricTypes: () => {},
  gymMetricPreferences: null,
  fetchGymMetricPreferences: () => {},
};

const UserContext =
  React.createContext<UserContextInterface>(UserContextDefaults);

interface UserProviderProps {}

export const UserProvider = ({
  children,
}: PropsWithChildren<UserProviderProps>) => {
  const { currentUser } = useAuth();
  const userID = currentUser;

  const [user, setUser] = useState<any>();
  const [users, setUsers] = useState<any[]>();

  const [gym, setGym] = useState<Gym | null | undefined>();

  const [metricTypes, setMetricTypes] = useState<
    MetricType[] | null | undefined
  >();

  const [gymMetricPreferences, setGymMetricPreferences] = useState<
    GymMetricPreference[] | null | undefined
  >();

  const [isLoadingUser, setIsLoadingUser] = useState<boolean>(false);

  const { setAlert } = useAlert();

  const handleError = useCallback(
    (err: any) => {
      setAlert({
        display: true,
        message: err?.message || err,
        type: "error",
      });
    },
    [setAlert]
  );

  const fetchUser = useCallback(async () => {
    if (userID) {
      setIsLoadingUser(true);
      try {
        const user = await userApi.getUser(userID);
        setUser(user);
      } catch (err) {
        handleError(err);
      } finally {
        setIsLoadingUser(false);
      }
    }
  }, [userID, handleError]);

  const fetchUsers = useCallback(async () => {
    if (userID) {
      try {
        const users = await userApi.getUsers();
        setUsers(users);
      } catch (err) {
        handleError(err);
      }
    }
  }, [userID, handleError]);

  const fetchGym = useCallback(async () => {
    if (user?.gym_id) {
      try {
        const gym = await gymApi.get(user.gym_id);
        setGym(gym);
      } catch (err) {
        handleError(err);
      }
    }
  }, [user, handleError]);

  const fetchMetricTypes = useCallback(async () => {
    try {
      const types = await metricTypeApi.getAll();
      setMetricTypes(types);
    } catch (err) {
      handleError(err);
    }
  }, [handleError]);


  const fetchGymMetricPreferences = useCallback(async () => {
    try {
      const preferences = await gymApi.getAllGymMetricPreferences();
      setGymMetricPreferences(preferences);
    } catch (err) {
      handleError(err);
    }
  }, [handleError]);

  useEffect(() => {
    fetchGymMetricPreferences();
  }, [fetchGymMetricPreferences]);

  useEffect(() => {
    fetchUser();
  }, [fetchUser]);

  useEffect(() => {
    fetchUsers();
  }, [fetchUsers]);

  useEffect(() => {
    fetchGym();
  }, [fetchGym]);

  useEffect(() => {
    fetchMetricTypes();
  }, [fetchMetricTypes]);

  return (
    <UserContext.Provider
      value={{
        user,
        fetchUser,
        isLoadingUser,
        users,
        fetchUsers,
        gym,
        fetchGym,
        metricTypes,
        fetchMetricTypes,
        gymMetricPreferences,
        fetchGymMetricPreferences,
      }}
    >
      {children}
    </UserContext.Provider>
  );
};

export default UserContext;
