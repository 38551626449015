import { AxiosRequestHeaders } from "axios";

export default class ApiTokenSupport {
  protected withAuthorization(headers?: AxiosRequestHeaders, token?: string) {
    return {
      headers: {
        ...(headers || {}),
        Authorization: `Bearer ${
          token || localStorage.getItem("genesis-access-token")
        }`,
      },
    };
  }
}
