// import any from "src/global/interfaces/User";
import axios from "src/utils/axios";
import ApiTokenSupport from "./token.support";
import UserType, { Trainer } from "src/interfaces/user.interface";
import Token from "src/interfaces/token.interface";

class UserApi extends ApiTokenSupport {
  /**
   * Login
   * @param email string
   * @param password string
   * @returns
   */
  async login(email: string, password: string): Promise<string> {
    const resp = await axios.post<string>("/api/user/login", {
      email,
      password,
    });
    return resp.data;
  }

  /**
   * Register
   * @param data any
   * @returns
   */
  async register(email: string, password: string): Promise<string> {
    const resp = await axios.post<string>("/api/user/register", {
      email,
      password,
    });
    return resp.data;
  }

  /**
   * Recover password
   * @param email
   * @returns
   */
  async recoverPassword(email: string): Promise<boolean> {
    const resp = await axios.post<boolean>(`/api/user/password-recovery`, {
      email,
    });

    return resp.data;
  }

  /**
   * Reset password
   * @param email
   * @returns
   */
  async resetPassword(newPassword: string, token: string): Promise<boolean> {
    const resp = await axios.post<boolean>(`/api/user/password-reset`, {
      newPassword,
      token,
    });

    return resp.data;
  }

  /**
   * Get user by ID
   * @param userID
   * @returns
   */
  async getUser(userID: number): Promise<any> {
    const resp = await axios.get<any>(
      `/api/user/${userID}`,
      this.withAuthorization()
    );

    return resp.data;
  }

  async verifyUser(token: string): Promise<any> {
    const resp = await axios.get<any>(
      `/api/user/verify`,
      this.withAuthorization({}, token)
    );
    return resp.data;
  }

  async join(token: string, data: {}): Promise<any> {
    const resp = await axios.post<any>(
      `/api/user/join`,
      data,
      this.withAuthorization({}, token)
    );
    return resp.data;
  }

  async getUsers(): Promise<any[]> {
    const resp = await axios.get<any[]>(`/api/user/`, this.withAuthorization());
    return resp.data;
  }

  async updateUser(data: {
    id: number;
    key: string;
    value: any;
  }): Promise<any> {
    const resp = await axios.put<any>(
      `/api/user/${data.id}`,
      data,
      this.withAuthorization()
    );
    return resp.data;
  }

  async create(user: UserType): Promise<any> {
    const resp = await axios.post<any>(
      `/api/user`,
      user,
      this.withAuthorization()
    );
    return resp.data;
  }

  async getTrainers(): Promise<Trainer[]> {
    const resp = await axios.get<Trainer[]>(
      `/api/user/trainers`,
      this.withAuthorization()
    );
    return resp.data;
  }

  async delete(userID: number): Promise<any> {
    const resp = await axios.delete<any>(
      `/api/user/${userID}`,
      this.withAuthorization()
    );
    return resp.data;
  }

  async resendInvite(user: UserType): Promise<boolean> {
    const resp = await axios.post<boolean>(
      `/api/user/invite`,
      { user },
      this.withAuthorization()
    );
    return resp.data;
  }

  async getTokenData(token: string): Promise<Token | null> {
    const resp = await axios.get<Token | null>(`/api/token/${token}`);
    return resp.data;
  }

  async passwordReset(email: string): Promise<boolean> {
    const resp = await axios.post<boolean>(`/api/user/password-reset`, {
      email,
    });
    return resp.data;
  }

  async updatePassword(token: Token, password: string): Promise<boolean> {
    const resp = await axios.post<boolean>(`/api/user/update-password`, {
      token,
      password,
    });
    return resp.data;
  }
}

export default new UserApi();
