import React from "react";

interface DeleteButtonProps {
  onClick?: (id?: number | undefined) => void;
}

const DeleteButton = ({ onClick }: DeleteButtonProps) => {
  return (
    <>
      <button
        className="btn btn-sm btn-circle btn-ghost absolute right-2 top-2"
        onClick={() => onClick && onClick()}
      >
        ✕
      </button>
    </>
  );
};

export default DeleteButton;
