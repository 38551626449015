import React from "react";
import { Link } from "react-router-dom";

const NotFound = () => {
  return (
    <div className="fixed w-full h-full flex justify-center bg-back-light">
      <div className="flex flex-col columns-1 items-center text-primary">
        <h1 className="text-[96px]">Oops!</h1>
        <h2 className="text-[36px]">404 - Page Not Found</h2>
        <div className="w-1/2 text-center mb-2">
          The page you are looking for might have been removed, had its name
          changed or is temporarily unavailable.
        </div>
        <div>
          <Link to="/athletes">
            <button className="btn btn-sm text-white btn-primary">
              Go Home
            </button>
          </Link>
        </div>
      </div>
    </div>
  );
};

export default NotFound;
