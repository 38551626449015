import { PropsWithChildren, createContext } from 'react';
import useThemeSwitcher from 'src/hooks/useThemeSwitcher';

interface ThemeContextValue {
  mode: string;
  setMode: (mode: string) => void;
}

const defaultState = {
  mode: 'light',
  setMode: () => {},
}

export const ThemeContext = createContext<ThemeContextValue>({
  ...defaultState
});

interface ThemeContextProps {}

export const ThemeProvider = ({ children }: PropsWithChildren<ThemeContextProps>) => {
  const [mode, setMode] = useThemeSwitcher();

  return <ThemeContext.Provider value={{ mode, setMode }}>{children}</ThemeContext.Provider>;
};
