import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { userApi } from "src/api";
import AuthButton from "src/components/buttons/AuthButton";
import FloatingPassword from "src/components/fields/FloatingPassword";
import useTokenGuard from "src/hooks/useTokenGuard";
import Token from "src/interfaces/token.interface";

const PasswordResetToken = () => {
  const { token } = useTokenGuard();

  const storedTokens: any = JSON.parse(
    localStorage.getItem("authTokens") || "null"
  ) as Token[];

  const recoveryToken = token || storedTokens.recovery;

  const navigate = useNavigate();

  const initialFormState = {
    password: "",
    repeatPassword: "",
  };

  const initialFormErrors: {
    password: string;
    repeatPassword: string;
  } = {
    password: "",
    repeatPassword: "",
  };

  interface FormErrors {
    password: string | null;
    repeatPassword: string | null;
  }

  const [formData, setFormData] = useState(initialFormState);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [formErrors, setFormErrors] = useState<FormErrors>(initialFormErrors);

  useEffect(() => {
    if (!recoveryToken) {
      navigate("/login");
    }
  }, [recoveryToken, navigate]);

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validate: any = (data: any) => {
    const errors: {
      password?: string;
      repeatPassword?: string;
    } = {};
    if (!data.password) {
      errors.password = "Password is required";
    }
    if (!data.repeatPassword) {
      errors.repeatPassword = "Password is required";
    }
    if (data.password !== data.repeatPassword) {
      errors.repeatPassword = "Passwords do not match";
    }
    return errors;
  };

  async function handleSubmit(e: any) {
    e.preventDefault();
    let errors = validate(formData);
    if (errors.password || errors.repeatPassword) {
      return setFormErrors(errors);
    } else {
      const { password } = formData;
      setSubmitting(true);
      const res = await userApi.updatePassword(recoveryToken, password);
      if (res) {
        navigate("/login", {
          state: {
            displayAlert: {
              type: "success",
              message: "Password updated successfully ",
            },
          },
        });
        const updatedTokens = {
          ...storedTokens,
          recovery: null,
        };
        localStorage.setItem("authTokens", JSON.stringify(updatedTokens));
      }
      setSubmitting(false);
    }
  }

  return (
    <>
      <div className="container mx-auto px-4">
        <div className="flex items-center justify-center ">
          <div className="w-full sm:w-8/12 md:w-7/12 lg:w-5/12 xl:w-4/12 2xl:w-[450px] px-2">
            <div className="relative flex flex-col min-w-0 break-words w-full mb-6 mt-28 shadow-lg rounded-lg bg-back-light group">
              <div className="absolute -inset-0.5 bg-gradient-to-tr from-gradientBlue to-gradientDarkBlue bg-error rounded-lg blur-lg opacity-75 group-hover:opacity-100 transition duration-1000 group-hover:duration-200 animate-tilt"></div>
              <div className="relative flex-auto px-4 lg:px-10 py-10 pt-10 bg-back-light rounded-2xl">
                <form onSubmit={handleSubmit}>
                  <div className="relative w-full mb-3">
                    <FloatingPassword
                      name="password"
                      label="Password"
                      value={formData.password}
                      onChange={handleChange}
                      error={formErrors.password}
                    />
                  </div>

                  <div className="relative w-full mb-3">
                    <FloatingPassword
                      label="Repeat Password"
                      name="repeatPassword"
                      value={formData.repeatPassword}
                      onChange={handleChange}
                      error={formErrors.repeatPassword}
                    />
                  </div>
                  <div className="text-center mt-6">
                    <AuthButton className="" loading={submitting} type="submit">
                      Reset Password
                    </AuthButton>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PasswordResetToken;
