import React, { useState } from "react";
import { useNavigate } from "react-router-dom";
import { userApi } from "src/api";
import Alert from "src/components/alert/Alert";
import AuthButton from "src/components/buttons/AuthButton";
import FloatingInput from "src/components/fields/FloatingInput";
import { regexEmail } from "src/helpers/constants";
import useAlert from "src/hooks/useAlert";

const PasswordReset = () => {
  const { alert, clearAlert, setAlert } = useAlert();

  const navigate = useNavigate();

  const initialFormState = {
    email: "",
  };

  const initialFormErrors: {
    email: string;
  } = {
    email: "",
  };

  interface FormErrors {
    email: string | null;
  }

  const [formData, setFormData] = useState(initialFormState);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [formErrors, setFormErrors] = useState<FormErrors>(initialFormErrors);

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validate: any = (data: any) => {
    const errors: {
      email?: string;
    } = {};
    if (!data.email) {
      errors.email = "Email is required";
    } else if (!regexEmail.test(data.email)) {
      errors.email = "Email is not a valid format";
    }
    return errors;
  };

  async function handleSubmit(e: any) {
    e.preventDefault();
    setFormErrors(initialFormErrors);
    let errors = validate(formData);
    if (errors.email || errors.password) {
      return setFormErrors(errors);
    } else {
      const { email } = formData;
      setSubmitting(true);
      try {
        const res = await userApi.passwordReset(email);
        if (res) {
          setFormData(initialFormState);
          setAlert({
            display: true,
            type: "success",
            message:
              "Please check your email",
          });
        } else {
          setAlert({
            display: true,
            type: "warning",
            message: "No user found with this email",
          });
        }
      } catch (err: any) {
        console.error(err);
        setAlert({
          display: true,
          type: "warning",
          message: "No user found with this email",
        });
      } finally {
        setSubmitting(false);
      }
    }
  }

  return (
    <>
      <div className="container mx-auto px-4">
        <div className="flex items-center justify-center ">
          <div className="w-full sm:w-8/12 md:w-7/12 lg:w-5/12 xl:w-4/12 2xl:w-[450px] px-2">
            <div className="relative flex flex-col min-w-0 break-words w-full mb-6 mt-28 shadow-lg rounded-lg bg-back-light group">
              <div className="absolute -inset-0.5 bg-gradient-to-tr from-gradientBlue to-gradientDarkBlue bg-error rounded-lg blur-lg opacity-75 group-hover:opacity-100 transition duration-1000 group-hover:duration-200 animate-tilt"></div>
              <div className="relative flex-auto px-4 lg:px-10 py-10 pt-10 bg-back-light rounded-2xl">
                <Alert alert={alert} onClose={clearAlert} />
                <form onSubmit={handleSubmit}>
                  <div className="relative w-full mb-3">
                    <FloatingInput
                      name="email"
                      label="Email"
                      value={formData.email}
                      onChange={handleChange}
                      error={formErrors.email}
                    />
                  </div>
                  <div className="text-center mt-6">
                    <AuthButton className="" loading={submitting} type="submit">
                      Reset Password
                    </AuthButton>
                  </div>
                </form>
              </div>
            </div>
            <div className="w-full flex items-center justify-center">
              <p
                className="hover:underline text-sm text-primary font-medium cursor-pointer"
                onClick={() => navigate("/login")}
              >
                Login
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default PasswordReset;
