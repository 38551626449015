function abbreviateMonth(month: string) {
  if (month === "January") {
    return "Jan";
  } else if (month === "February") {
    return "Feb";
  } else if (month === "March") {
    return "Mar";
  } else if (month === "April") {
    return "Apr";
  } else if (month === "May") {
    return "May";
  } else if (month === "June") {
    return "Jun";
  } else if (month === "July") {
    return "Jul";
  } else if (month === "August") {
    return "Aug";
  } else if (month === "September") {
    return "Sep";
  } else if (month === "October") {
    return "Oct";
  } else if (month === "November") {
    return "Nov";
  } else if (month === "December") {
    return "Dec";
  }
}

const months = [
  "January",
  "February",
  "March",
  "April",
  "May",
  "June",
  "July",
  "August",
  "September",
  "October",
  "November",
  "December",
];

export default function parseMonthDayYear(
  dateObject: Date, // Accept Date object as input
  epochToAdd: number = 0,
  noYear?: boolean
): string | null {
  if (!dateObject) return null;

  const dateString = String(dateObject);

  const year = dateString.split("-")[0];
  const month = dateString.split("-")[1];
  const day = Number(dateString.split("-")[2].split("T")[0]);

  const monthName = months[parseInt(month) - 1];

  return `${abbreviateMonth(monthName)} ${day} ${noYear ? "" : year}`;
}
