import {
  PropsWithChildren,
  useState,
  createContext,
  useCallback,
  useEffect,
} from "react";
import { Outlet, useNavigate } from "react-router-dom";
import Token from "src/interfaces/token.interface";
import { useParams } from "react-router-dom";
import { userApi } from "src/api";

interface ContextState {
  token?: Token;
  isCheckingToken: boolean;
}

const defaultValue = {
  token: undefined,
  isCheckingToken: false,
};

export const TokenContext = createContext<ContextState>(defaultValue);

const TokenGuardContext = ({ children }: PropsWithChildren<{}>) => {
  const navigate = useNavigate();

  const { tokenValue } = useParams();

  const [token, setToken] = useState<Token>();

  const [tokens, setTokens] = useState<Record<string, Token>>(() => {
    const storedTokens = localStorage.getItem("authTokens");
    return storedTokens ? JSON.parse(storedTokens) : {};
  });

  const [isCheckingToken, setIsCheckingToken] = useState<boolean>(false);

  const verifyToken = useCallback(
    async (tokenValueString: string) => {
      setIsCheckingToken(true);
      if (!tokenValueString) {
        setIsCheckingToken(false);
        return;
      }

      try {
        const tokenData: Token | null = await userApi.getTokenData(
          tokenValueString
        );

        if (tokenData) {
          setToken(tokenData);

          const { context } = tokenData;

          const updatedTokens = {
            ...tokens,
            [context]: tokenData,
          };

          // Store updated tokens in local storage
          localStorage.setItem("authTokens", JSON.stringify(updatedTokens));

          setTokens(updatedTokens);

          if (token?.consumed) {
            navigate("/invalid-token");
          } else {
            switch (context) {
              case "registration":
                navigate("/register");
                break;
              case "recovery":
                navigate("/reset-password");
            }
          }
        } else {
          navigate("/invalid-token");
        }
      } catch (e) {
        navigate("/invalid-token");
      } finally {
        setIsCheckingToken(false);
      }
    },
    [setToken, navigate]
  );

  useEffect(() => {
    if (tokenValue) {
      verifyToken(tokenValue);
    }
  }, [tokenValue, verifyToken]);

  return (
    <TokenContext.Provider value={{ token, isCheckingToken }}>
      {children || <Outlet />}
    </TokenContext.Provider>
  );
};

export default TokenGuardContext;
