import { useState, useEffect } from "react";

const useThemeSwitcher = () => {
  const [mode, setMode] = useState<any>(() => {
    return localStorage.getItem("mode");
  });
  // const [mode, setMode] = useState<string>();
  useEffect(() => {
    if (mode === "dark") {
      document.body.classList.add("dark");
      localStorage.setItem("mode", "dark");
    } else {
      document.body.classList.remove("dark");
      localStorage.setItem("mode", "light");
    }
  }, [mode]);

  return [mode, setMode];
};

export default useThemeSwitcher;
