import React, { useState, useEffect, useRef } from "react";
import { Athlete } from "src/interfaces/athlete.interface";
import FloatingInput from "../fields/FloatingInput";
import { athleteApi } from "src/api";
import useModal from "src/hooks/private/useModal";
import { faFloppyDisk } from "@fortawesome/free-regular-svg-icons";
import ModalButton from "../buttons/ModalButton";
import useAlert from "src/hooks/useAlert";

interface EditAthleteModalProps {
  athlete: Athlete;
  fetchAthlete: () => void;
}

const EditAthleteModal = ({ athlete, fetchAthlete }: EditAthleteModalProps) => {

  const inputRef = useRef<HTMLInputElement>(null);

  const { setShowModal } = useModal();

  const { setAlert } = useAlert();

  const [athleteName, setAthleteName] = useState<string>(athlete.name);

  const [isSaving, setIsSaving] = useState<boolean>(false);

  const initialFormErrors: {
    name: string;
  } = {
    name: "",
  };

  interface FormErrors {
    name: string;
  }

  const [formErrors, setFormErrors] = useState<FormErrors>(initialFormErrors);

  const validate: any = (name: string) => {
    const errors: {
      name?: string;
    } = {};
    if (!name.trim()) {
      errors.name = "Athlete name is required";
      return errors;
    }
  };

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  const handleSaveSubmit = async () => {
    let errors = validate(athleteName);
    if (errors?.name) {
      return setFormErrors(errors);
    } else {
      setFormErrors(initialFormErrors);
      try {
        setIsSaving(true);
        await athleteApi.update({
          ...athlete,
          name: athleteName,
        });
      } catch (e: any) {
        console.log(e);
        setAlert({
          display: true,
          type: "error",
          message: e.message || "An error has occurred",
        });
      } finally {
        setIsSaving(false);
        setShowModal(false);
        fetchAthlete();
        setAlert({
          display: true,
          type: "info",
          message: `${athleteName} was successfully edited`,
        });
      }
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      handleSaveSubmit();
    }
  };

  return (
    <div className="py-4 pb-6">
      <FloatingInput
        ref={inputRef}
        value={athleteName}
        onChange={(e: any) => setAthleteName(e.target.value)}
        label="Athlete Name"
        name="customField"
        handleKeyDown={handleKeyDown}
        type="text"
        error={formErrors.name}
      />
      <div className="mt-4 -mb-4">
        <ModalButton
          isLoading={isSaving}
          onSubmit={handleSaveSubmit}
          label={"Save"}
          loadingLabel="Saving"
          icon={faFloppyDisk}
        />
      </div>
    </div>
  );
};

export default EditAthleteModal;
