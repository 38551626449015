import React, {
  ForwardedRef,
  forwardRef,
  ChangeEventHandler,
  ReactNode,
  useState,
} from "react";
import classNames from "classnames";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface FloatingInputProps {
  name: string;
  label?: string | ReactNode;
  value?: string;
  onChange?: ChangeEventHandler;
  error?: string | null;
  hasComma?: boolean;
  labelProps?: {
    className?: string;
    htmlFor?: string;
  };
  inputProps?: {
    className?: string;
  };
  errorProps?: {
    className?: string;
  };
  disabled?: boolean;
  handleKeyDown?: (e: React.KeyboardEvent<HTMLInputElement>) => void;
}

const FloatingInput = forwardRef<HTMLInputElement, FloatingInputProps>(
  (
    {
      name,
      label,
      value,
      error,
      labelProps,
      inputProps,
      errorProps,
      onChange,
      hasComma = false,
      disabled = false,
      handleKeyDown,
    },
    ref: ForwardedRef<HTMLInputElement>
  ) => {

    type InputType = "password" | "text";

    const [inputType, setInputType] = useState<InputType>("password");
  
    const handleInputTypeChange = () => {
      setInputType((prevState: InputType) => {
        if (prevState === "password") {
          return "text";
        } else {
          return "password";
        }
      });
    };
    
    const errorClassName = classNames(
      "absolute bg-back-light text-error text-2xs md:text-xs italic left-4 top-[42px] px-1",
      errorProps?.className
    );

    return (
      <>
        <div className="relative">
          <input
            ref={ref}
            id={name}
            type={inputType}
            className={
              error
                ? "block px-2.5 pb-2.5 pt-4 w-full text-[17px] text-black font-semibold bg-back-light rounded-lg border border-error appearance-none dark:border-error dark:focus:border-error focus:outline-none focus:ring-0 focus:border-error peer placeholder:text-zinc-500"
                : "block pl-5 pb-2.5 pt-4 w-full text-[17px] text-black font-semibold bg-back-light rounded-lg border border-primary appearance-none dark:focus:border-primary focus:outline-none focus:ring-0 focus:border-primary peer placeholder:text-zinc-500"
            }
            placeholder=" "
            style={{
              outline: "none",
            }}
            onChange={onChange}
            value={value}
            name={name}
            onKeyDown={handleKeyDown}
          />
           {value?.length ? (
          <div
            className="absolute top-3.5 right-3"
            onClick={handleInputTypeChange}
          >
            <FontAwesomeIcon
              icon={inputType === "text" ? faEye : faEyeSlash}
              className="text-lg text-secondary w-7"
            />
          </div>
        ) : null}
          <label
            htmlFor={name}
            className="absolute text-sm text-text-black duration-300 transform -translate-y-4 scale-75 top-2 z-10 origin-[0] bg-back-light px-2 peer-focus:px-3 peer-focus:text-text-black peer-focus:dark:text-text-black peer-placeholder-shown:scale-100 peer-placeholder-shown:-translate-y-1/2 peer-placeholder-shown:top-1/2 peer-focus:top-2 peer-focus:scale-75 peer-focus:-translate-y-4 left-2.5 font-medium"
          >
            {label}
          </label>
          {error && (
            <p {...errorProps} className={errorClassName}>
              {error}
            </p>
          )}
        </div>
      </>
    );
  }
);

export default FloatingInput;
