import React, { useState } from 'react';
import { AnimatePresence, motion } from 'framer-motion';
import Papa from 'papaparse';
import * as XLSX from 'xlsx';
import { useNavigate } from 'react-router-dom';
import useModal from 'src/hooks/private/useModal';
import ModalButton from '../buttons/ModalButton';
import { faFileLines } from '@fortawesome/free-regular-svg-icons';

interface AthleteNameRow {
  Name: string;
}

const ImportAthletesModal = () => {
  const [csvFile, setCsvFile] = useState<File | null>(null);
  const [error, setError] = useState<string | null>(null);
  const [isImporting, setIsImporting] = useState(false);
  const { setShowModal } = useModal();
  const navigate = useNavigate();

  const handleChangeFile = ({ target: { files } }: React.ChangeEvent<HTMLInputElement>) => {
    setError(null);
    if (!files) return;
    const fileType = files[0].type;
    const validTypes = ['text/csv', 'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet', 'application/vnd.ms-excel'];
    
    if (!validTypes.includes(fileType)) {
      setError('File must be .csv or .xlsx/.xls');
      return;
    }
    
    setCsvFile(files[0]);
  };

  const readFile = () => {
    if (!csvFile) return setError('Enter a valid file');
    setIsImporting(true);

    const reader = new FileReader();

    reader.onload = async ({ target }: any) => {
      let parsedData: any[];
      if (csvFile.type === 'text/csv') {
        parsedData = Papa.parse(target.result, { header: true }).data;
      } else {
        const workbook = XLSX.read(target.result, { type: 'binary' });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        parsedData = XLSX.utils.sheet_to_json(sheet);
      }

      console.log(parsedData)

      const athleteNames: AthleteNameRow[] = parsedData.map((row: AthleteNameRow) => ({ Name: row.Name }));
      
      setShowModal(false);
      navigate('/athletes/edit-import-names', { state: { data: athleteNames } });
    };

    if (csvFile.type === 'text/csv') {
      reader.readAsText(csvFile);
    } else {
      reader.readAsBinaryString(csvFile);
    }

    setIsImporting(false);
  };

  return (
    <>
      <AnimatePresence>
        <div className='mb-4'>
          <input type='file' className='file-input file-input-bordered w-full max-w-xs' onChange={handleChangeFile} />
          {error && (
            <motion.p
              initial={{ opacity: 0, scale: 0.5 }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.5 }}
              className='text-error font-medium'
            >
              {error}
            </motion.p>
          )}
          {csvFile && !error && (
            <div className='mt-4 -mb-4'>
              <ModalButton
                isLoading={isImporting}
                onSubmit={readFile}
                label='Import'
                loadingLabel='Importing'
                icon={faFileLines}
              />
            </div>
          )}
        </div>
      </AnimatePresence>
    </>
  );
};

export default ImportAthletesModal;
