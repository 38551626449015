import React, { useState } from "react";
import { faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { userApi } from "src/api";
import useModal from "src/hooks/private/useModal";
import useAlert from "src/hooks/useAlert";
import { Trainer } from "src/interfaces/user.interface";
import ModalButton from "../buttons/ModalButton";

interface DeleteTrainerModalProps {
  trainer: Trainer;
  fetchGyms: () => {};
  fetchTrainers: () => {};
}

const DeleteTrainerModal = ({
  trainer,
  fetchGyms,
  fetchTrainers,
}: DeleteTrainerModalProps) => {
  const { setShowModal } = useModal();

  const { setAlert } = useAlert();

  const [isDeleting, setIsDeleting] = useState<boolean>(false);

  const handleDeleteSubmit = async () => {
    setIsDeleting(true);
    try {
      await userApi.delete(trainer?.id);
      fetchTrainers();
      fetchGyms();
    } catch (e: any) {
      console.log(e);
      setAlert({
        display: true,
        type: "error",
        message: e.message || "An error has occurred",
      });
    } finally {
      setIsDeleting(false);
      setShowModal(false);
      setAlert({
        display: true,
        type: "info",
        message: `${trainer.name} was permanently deleted`,
      });
    }
  };

  return (
    <>
      <div>
        <p className="mb-2">
          Are you sure you want to delete{" "}
          <span className="font-bold">{trainer?.name}</span> and all their data?
          This action cannot be undone!
        </p>
        <ModalButton
          isLoading={isDeleting}
          onSubmit={handleDeleteSubmit}
          label={"Delete"}
          loadingLabel="Deleting"
          icon={faTrashCan}
          loaderColor={"#F87272"}
          labelColor={"#F87272"}
        />
      </div>
    </>
  );
};

export default DeleteTrainerModal;
