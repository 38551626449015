import { faChartLine, faList } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React, { useEffect, useState } from "react";
import { motion } from "framer-motion";
import ChartType from "src/types/ChartTypes";

interface ViewSliderProps {
  type: ChartType;
  handleChange: (type: ChartType) => void;
}

const ViewSlider = ({ type, handleChange }: ViewSliderProps) => {
  const [sliderStyle, setSliderStyle] = useState({ left: 0, width: 0 });
  const [activeType, setActiveType] = useState<ChartType | null>(null);

  useEffect(() => {
    const targetElement = document.getElementById(type);
    if (targetElement) {
      setSliderStyle({
        left: targetElement.offsetLeft,
        width: targetElement.offsetWidth,
      });
    }
    // Delay the text color change to match the sliding animation
    const timer = setTimeout(() => {
      setActiveType(type);
    }, 90);

    return () => clearTimeout(timer);
  }, [type]);

  return (
    <>
      <div className="relative bg-light-focus py-1 flex gap-4 rounded-md w-80">
        <motion.div
          initial={false}
          animate={sliderStyle}
          className="absolute bottom-0 left-0 h-8 bg-primary z-20 w-40 rounded-md"
        ></motion.div>
        <div
          id="graph"
          className={
            activeType === "graph"
              ? "flex items-center justify-center gap-2.5 z-30 w-40 text-white cursor-default"
              : "flex items-center justify-center gap-2.5 z-30 w-40 cursor-pointer"
          }
          onClick={() => handleChange("graph")}
        >
          <FontAwesomeIcon icon={faChartLine} />
          <p className="font-medium">Graph View</p>
        </div>
        <div
          id="list"
          className={
            activeType === "list"
              ? "flex items-center justify-center gap-2.5 z-30 w-40 text-white cursor-default"
              : "flex items-center justify-center gap-2.5 z-30 w-40 cursor-pointer"
          }
          onClick={() => handleChange("list")}
        >
          <FontAwesomeIcon icon={faList} />
          <p className="font-medium">List View</p>
        </div>
      </div>
    </>
  );
};

export default ViewSlider;
