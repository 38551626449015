import classNames from "classnames";
import { MouseEventHandler, PropsWithChildren } from "react";
import { IconProp } from "@fortawesome/fontawesome-svg-core";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import ButtonLoader from "../loading/ButtonLoader";
import { motion } from "framer-motion";
interface AuthButtonsProps {
  title?: string;
  className?: string;
  type?: "button" | "reset" | "submit" | undefined;
  loading?: boolean;
  disabled?: boolean;
  icon?: IconProp;
  onClick?: MouseEventHandler;
}

const AuthButton = ({
  title,
  className,
  type = "button",
  loading,
  disabled,
  icon,
  onClick,
  children,
}: PropsWithChildren<AuthButtonsProps>) => {
  const buttonClass = classNames(
    "btn bg-gradient-to-tr text-white from-gradientBlue to-gradientDarkBlue font-bold text-lg gap-2 text- uppercase w-full border-none",
    className
  );
  return (
    <button
      className={buttonClass}
      type={type}
      disabled={disabled}
      onClick={onClick}
    >
      <div className="flex items-center justify-center">
        <div>
          {icon && !loading && (
            <FontAwesomeIcon
              icon={icon}
              className="text-white text-lg mr-0.5"
            />
          )}
          {loading && <ButtonLoader />}
        </div>
        <motion.div
          initial={{ x: 0 }}
          animate={loading ? { x: 10 } : { x: 0 }}
          transition={{ duration: 0.5 }}
        >
          {children}
        </motion.div>
      </div>
    </button>
  );
};

export default AuthButton;
