import { PropsWithChildren, createContext, ReactNode, useState } from "react";
import ModalCard from "src/components/modals/ModalCard";

export interface ModalOptionsProps {
  title: string;
  body: ReactNode;
  onSubmit?: () => Promise<void>;
  showButtons?: boolean;
}

interface ModalContextValue {
  showModal: boolean;
  setShowModal: (showCard: boolean) => void;
  openModalWith: (o: ModalOptionsProps) => void;
}

const defaultState = {
  showModal: false,
  setShowModal: () => {},
  openModalWith: () => {},
};

export const ModalContext = createContext<ModalContextValue>({
  ...defaultState,
});

interface ModalContextProps {}

export const ModalProvider = ({
  children,
}: PropsWithChildren<ModalContextProps>) => {
  const [showModal, setShowModal] = useState<boolean>(defaultState.showModal);

  const [modalProps, setModalProps] = useState<ModalOptionsProps>({
    title: "",
    body: <></>
  });

  const openModalWith = (o: ModalOptionsProps) => {
    setModalProps(o);
    setShowModal(true);
  };

  return (
    <ModalContext.Provider
      value={{ showModal, setShowModal,  openModalWith }}
    >
      {children}
      <ModalCard {...modalProps} />
    </ModalContext.Provider>
  );
};