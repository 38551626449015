import { ReactNode } from "react";
import HeaderNav from "src/components/navigation/HeaderNav";
interface PublicLayoutProps {
  children: ReactNode;
}

const PublicLayout = ({ children }: PublicLayoutProps) => {
  return (
    <>
      <div className="h-screen overscroll-y-none bg-back-light">
        <HeaderNav />
        <div className="relative top-[62px] w-full overscroll-y-none">
          {children}
        </div>
      </div>
    </>
  );
};

export default PublicLayout;
