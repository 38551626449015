import React from "react";
import { motion, AnimatePresence } from "framer-motion";

interface NamePlateProps {
  name: string;
}

const NamePlate = ({ name }: NamePlateProps) => {
  const scaleVariants = {
    initial: { scale: 1, opacity: 1 },
    out: { scale: 0.7, opacity: 0, },
    in: { scale: 1, opacity: 1},
  };

  return (
    <AnimatePresence mode="wait">
      <motion.div
        layout
        key={name} 
        className="xs:text-[24px] sm:text-[30px] lg:text-[40px] font-bold text-center"
        initial="initial" 
        animate="in"
        exit="out"
        variants={scaleVariants}
        transition={{ duration: 0.2}}
      >
        {name}
      </motion.div>
    </AnimatePresence>
  );
};

export default NamePlate;
