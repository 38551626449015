import React from "react";

export interface ToolbarItemProps {
  isActive: boolean;
  title: string;
  onClick: (e: any) => void;
}

export const ToolbarItem = ({ isActive, title, onClick }: ToolbarItemProps) => {
  return (
    <>
      <div
        className={
          isActive
            ? "tab tab-xs xs:tab-sm md:tab-md bg-primary rounded-md text-white font-semibold"
            : "tab tab-xs xs:tab-sm md:tab-md text-title-text-light dark:text-[#73868F]"
        }
        onClick={onClick}
      >
        <span className="">{title}</span>
      </div>
    </>
  );
};

interface TrendsToolbarProps {
  isActiveLinks: any;
  handleActive: (str: string) => void;
}

const TrendsToolbar = ({ isActiveLinks, handleActive }: TrendsToolbarProps) => {
  return (
    <div className="tabs tabs-boxed  flex items-center justify-center ">
      <ToolbarItem
        title={"PRs"}
        isActive={isActiveLinks["PRs"]}
        onClick={() => handleActive("PRs")}
      />
      <ToolbarItem
        title={"Progress"}
        isActive={isActiveLinks["Progress"]}
        onClick={() => handleActive("Progress")}
      />
    </div>
  );
};

export default TrendsToolbar;
