/* eslint-disable react-hooks/exhaustive-deps */
import React, { useState, useEffect } from "react";
import { useLocation, useParams } from "react-router-dom";
import { athleteApi, metricApi } from "src/api";
import NamePlate from "src/components/athlete/NamePlate";
import ActionButton from "src/components/buttons/ActionButton";
import Chart from "src/components/charts/Chart";
// import StatsOverview from "src/components/charts/StatsOverview";
import AddAthleteDataModal from "src/components/modals/AddAthleteDataModal";
import useModal from "src/hooks/private/useModal";
import { Athlete as AthleteType } from "src/interfaces/athlete.interface";
import Metric from "src/interfaces/metric.interface";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEllipsis, faPlus } from "@fortawesome/free-solid-svg-icons";
import {
  faFilePdf,
  faPenToSquare,
  faTrashCan,
} from "@fortawesome/free-regular-svg-icons";
import EditAthleteModal from "src/components/modals/EditAthleteModal";
import { Helmet } from "react-helmet";
import { ChartData } from "chart.js";
import moment from "moment";
import html2pdf from "html2pdf.js";
import PDFOutputHTML from "src/components/athlete/PDFOutputHTML";
import DeleteAthleteModal from "src/components/modals/DeleteAthleteModal";
import useUserContext from "src/hooks/private/useUserContext";
import MetricType from "src/interfaces/metricType.interface";
import { metricConversionMap } from "src/utils/conversionFunctions";

export interface ExerciseMetrics {
  vertical: Metric[];
  strength: Metric[];
  acceleration: Metric[];
  speed: Metric[];
}

const Athlete = () => {
  const { athleteID } = useParams();

  const { openModalWith } = useModal();

  const { gymMetricPreferences, metricTypes } = useUserContext();

  const { gym } = useUserContext();

  const navigate = useNavigate();

  const location = useLocation();

  const [athlete, setAthlete] = useState<AthleteType | null>(null);

  const [metrics, setMetrics] = useState<ExerciseMetrics>();

  const [showPDFContent, setShowPDFContent] = useState(false);

  const getPreferredMetricType = (
    exerciseId: number,
    gymId: number
  ): MetricType | undefined => {
    const preference = gymMetricPreferences?.find(
      (pref) => pref.exercise_id === exerciseId
    );
    const metricType = metricTypes?.find(
      (type) => type.id === preference?.preferred_metric_type_id
    );

    return metricType;
  };

  const fetchAthlete = async () => {
    if (athleteID) {
      try {
        const res = await athleteApi.get(Number(athleteID));
        setAthlete(res);
      } catch (e) {
        console.log(e);
      }
    }
  };

  const fetchMetrics = async () => {
    if (athleteID) {
      try {
        const exerciseTypes = ["vertical", "strength", "acceleration", "speed"];
        const fetchPromises = exerciseTypes.map((type, index) =>
          metricApi.getByAthleteID(Number(athleteID), index + 1)
        );

        const [vertical, strength, acceleration, speed] = await Promise.all(
          fetchPromises
        );

        setMetrics({ vertical, strength, acceleration, speed });
      } catch (e) {
        console.log(e);
      }
    }
  };

  useEffect(() => {
    window.scrollTo(0, 0);
    fetchAthlete();
    fetchMetrics();
  }, [athleteID]);

  const convertMetric = (metric: Metric): { value: number; unit: string } => {
    const preference = gymMetricPreferences?.find(
      (pref) => pref.exercise_id === metric.exercise_id
    );
    const metricType = metricTypes?.find(
      (type) => type.id === preference?.preferred_metric_type_id
    );

    if (metricType) {
      // Check if there's a conversion function for the given metric_type_id
      const conversion = metricConversionMap[metricType.id];
      if (conversion) {
        return {
          value: Number(conversion.toMPH(metric.value).toFixed(2)),
          unit: metricType.unit,
        };
      }

      // No conversion needed
      return { value: metric.value, unit: metricType.unit || "" };
    }

    // Fallback if no metric type is found
    return { value: metric.value, unit: "" };
  };

  const convertMetricsToDataset = (
    metrics: Metric[]
  ): ChartData<"line", { x: Date | string; y: number }[], unknown> => {
    const data = metrics.map((metric) => {
      const convertedMetric = convertMetric(metric);
      return {
        x: new Date(metric.date),
        y: convertedMetric.value,
      };
    });

    return {
      datasets: [
        {
          backgroundColor: "#163E63",
          data,
          borderWidth: 2,
          borderColor: "#163E63",
          pointRadius: 2,
          pointHitRadius: 15,
          tension: 0.4,
        },
      ],
    };
  };

  const verticalData = metrics?.vertical
    ? convertMetricsToDataset(metrics.vertical)
    : null;
  const strengthData = metrics?.strength
    ? convertMetricsToDataset(metrics.strength)
    : null;
  const accelerationData = metrics?.acceleration
    ? convertMetricsToDataset(metrics.acceleration)
    : null;
  const speedData = metrics?.speed
    ? convertMetricsToDataset(metrics.speed)
    : null;

  const handleAddData = () => {
    openModalWith({
      title: "",
      body: athlete && (
        <AddAthleteDataModal
          athlete={athlete}
          fetchAthlete={fetchAthlete}
          fetchMetrics={fetchMetrics}
        />
      ),
      showButtons: false,
    });
  };

  const handleBackButton = () => {
    if (location.state?.fromPage) {
      navigate(-1);
    } else {
      navigate("/athletes");
    }
  };

  const handleAddAthlete = () => {
    openModalWith({
      title: "",
      body: athlete && (
        <EditAthleteModal athlete={athlete} fetchAthlete={fetchAthlete} />
      ),
      showButtons: false,
    });
  };

  const verticalLabels =
    metrics?.vertical?.map((metric) =>
      moment(metric.date).format("MMM DD, YYYY")
    ) || [];
  const strengthLabels =
    metrics?.strength?.map((metric) =>
      moment(metric.date).format("MMM DD, YYYY")
    ) || [];
  const accelerationLabels =
    metrics?.acceleration?.map((metric) =>
      moment(metric.date).format("MMM DD, YYYY")
    ) || [];
  const speedLabels =
    metrics?.speed?.map((metric) =>
      moment(metric.date).format("MMM DD, YYYY")
    ) || [];

  const handleExportToPDF = () => {
    setShowPDFContent(true);
  };

  useEffect(() => {
    if (showPDFContent) {
      const options = {
        margin: [10, 10, 10, 10] as [number, number, number, number],
        filename: `${athlete?.name}_Chart.pdf`,
        image: { type: "jpeg" as "jpeg", quality: 0.98 },
        html2canvas: {
          scale: 2,
          logging: true,
          dpi: 192,
          letterRendering: true,
          willReadFrequently: true,
        },
        jsPDF: {
          unit: "mm" as "mm",
          format: "a4",
          orientation: "landscape" as "landscape",
        },
      };

      const timer = setTimeout(() => {
        const element = document.getElementById("pdf");
        if (element) {
          html2pdf()
            .from(element)
            .set(options)
            .output("blob")

            .then((blob) => {
              // Create a Blob URL for the PDF
              const blobUrl = URL.createObjectURL(blob);

              // Open the Blob URL in a new tab
              window.open(blobUrl, "_blank");

              // Hide the PDF content after generation
              setShowPDFContent(false);
            })
            .catch((error) => {
              console.error("Error generating PDF:", error);
              setShowPDFContent(false);
            });
        }
      }, 500);

      // Clean up the timer if the component unmounts
      return () => clearTimeout(timer);
    }
  }, [showPDFContent]);

  const handleDeleteAthlete = () => {
    openModalWith({
      title: "",
      showButtons: false,
      body: athlete && <DeleteAthleteModal athlete={athlete} />,
    });
  };

  return (
    <>
      {athlete?.name && (
        <Helmet>
          <title>{athlete?.name} | Genesis Training Academy</title>
        </Helmet>
      )}
      <div className="flex items-center justify-between bg-white z-10 top-42 flex-col w-full overscroll-y-auto">
        <div className="flex items-center justify-center mb-4 bg-back-light w-full px-4 sm:px-0 gap-4 sm:gap-12">
          <ActionButton label={"← Back"} onClick={handleBackButton} />
          <NamePlate name={athlete?.name || ""} />
          <div className="dropdown dropdown-end  ">
            <label
              tabIndex={0}
              className="btn m-1 normal-case bg-back-light border-text-nonfocus border rounded-md px-3 h-7 text-sm btn-xs hover:bg-back-light border-1 shadow-sm text-text-light"
            >
              <FontAwesomeIcon icon={faEllipsis} className="" />
            </label>
            <ul
              tabIndex={0}
              className="dropdown-content z-[1] menu p-2 shadow bg-base-100 rounded-box w-52"
            >
              <li>
                <button onClick={handleAddData}>
                  <FontAwesomeIcon icon={faPlus} />
                  Add Data
                </button>
              </li>
              <li>
                <button onClick={handleExportToPDF}>
                  <FontAwesomeIcon icon={faFilePdf} />
                  Download PDF
                </button>
              </li>
              <li>
                <button onClick={handleAddAthlete}>
                  <FontAwesomeIcon icon={faPenToSquare} />
                  Edit Athlete
                </button>
              </li>
              <li>
                <button onClick={handleDeleteAthlete}>
                  <FontAwesomeIcon icon={faTrashCan} />
                  Delete Athlete
                </button>
              </li>
            </ul>
          </div>
        </div>
        <div className="w-9/12">
          {verticalData && metrics?.vertical && gym?.is_power && (
            <div className="my-12">
              <Chart
                chartData={verticalData}
                title={"Power"}
                unit={getPreferredMetricType(1, gym.id)?.unit || "lbs"}
                metrics={metrics?.vertical}
                labels={verticalLabels}
                fetchMetrics={fetchMetrics}
              />
            </div>
          )}
          {strengthData && metrics?.strength && gym?.is_strength && (
            <div className="my-12">
              <Chart
                chartData={strengthData}
                title={"Strength"}
                unit={getPreferredMetricType(2, gym.id)?.unit || "lbs"}
                metrics={metrics?.strength}
                labels={strengthLabels}
                fetchMetrics={fetchMetrics}
              />
            </div>
          )}
          {accelerationData &&
            metrics?.acceleration &&
            gym?.is_acceleration && (
              <div className="my-12">
                <Chart
                  chartData={accelerationData}
                  title={"Agility"}
                  unit={getPreferredMetricType(3, gym.id)?.unit || "seconds"}
                  metrics={metrics?.acceleration}
                  labels={accelerationLabels}
                  fetchMetrics={fetchMetrics}
                  invertChart={true}
                />
              </div>
            )}
          {speedData && metrics?.speed && gym?.is_speed && (
            <div className="my-12">
              <Chart
                chartData={speedData}
                title={"Speed"}
                unit={getPreferredMetricType(4, gym.id)?.unit || "mph"}
                metrics={metrics?.speed}
                labels={speedLabels}
                fetchMetrics={fetchMetrics}
                invertChart={false}
              />
            </div>
          )}
        </div>
        {athlete &&
          verticalData &&
          strengthData &&
          accelerationData &&
          speedData &&
          showPDFContent && (
            <PDFOutputHTML
              athlete={athlete}
              verticalData={verticalData}
              strengthData={strengthData}
              accelerationData={accelerationData}
              speedData={speedData}
            />
          )}
      </div>
    </>
  );
};

export default Athlete;
