import React, { useState } from "react";
import PersonalRecords from "src/components/trends/PersonalRecords";
import Progress from "src/components/trends/Progress";
import TrendsToolbar from "src/components/trends/TrendsToolbar";

const Trends = () => {

  const [isActiveLinks, setIsActive] = useState({
    PRs: true,
    Progress: false,
  });

  const handleActive = (title: string) => {
    setIsActive({
      PRs: false,
      Progress: false,
      [title]: true,
    });
  };

  return (
    <>
      <div className="w-full flex flex-col items-center justify-center">
        <div className="mb-2">
          <TrendsToolbar
            isActiveLinks={isActiveLinks}
            handleActive={handleActive}
          />
        </div>
        {isActiveLinks["PRs"] && <PersonalRecords />}
        {isActiveLinks["Progress"] && <Progress />}
      </div>
    </>
  );
};

export default Trends;
