import { ReactNode } from "react";
import { motion, AnimatePresence } from "framer-motion";

/***** Navigation ******/

import Alert from "src/components/alert/Alert";

/***** Hooks *****/
import useAlert from "src/hooks/useAlert";
import HeaderNav from "src/components/navigation/HeaderNav";

interface PrivateLayoutProps {
  children: ReactNode;
}

const PrivateLayout = ({ children }: PrivateLayoutProps) => {
  const { alert, clearAlert } = useAlert();

  const transition = { type: "tween", duration: 0.5 };

  const alertAnimation = {
    initial: { y: -50, opacity: 0, transition },
    animate: { y: 0, opacity: 1, transition },
    exit: { y: -50, opacity: 0, transition },
  };

  const contentAnimation = {
    initial: { opacity: 0, transition },
    animate: { opacity: 1, transition },
    exit: { opacity: 0, transition },
  };

  return (
    <>
      <div className="bg-back-light h-screen w-full">
        <HeaderNav />
        <AnimatePresence>
          <div
            key={"alert-div-onDisplay"}
            className="w-full flex items-center justify-center"
          >
            <div className="relative w-1/2 my-1 top-[73px]">
              {alert.display && (
                <motion.div
                  className="absolute top-0 right-0 left-0 w-full z-40"
                  variants={alertAnimation}
                  initial="initial"
                  animate="animate"
                  exit="exit"
                >
                  <Alert alert={alert} onClose={clearAlert} />
                </motion.div>
              )}
            </div>
          </div>
        </AnimatePresence>
        <motion.div
          // layout
          className="mt-24 px-2 xs:px-6 sm:px-8 md:px-0 md:flex md:justify-center"
          variants={contentAnimation}
          initial="initial"
          animate="animate"
          exit="exit"
          key={"main-div-display"}
        >
          {children}
        </motion.div>
      </div>
    </>
  );
};

export default PrivateLayout;
