import axios from "axios";
import { createBrowserHistory } from "history";

const axiosInt = axios.create({
  baseURL: process.env.REACT_APP_BACKEND_URL,
});

// Create a history object
const history = createBrowserHistory();

axiosInt.interceptors.response.use(
  (response) => response,
  (error) => {
    if (error.response && error.response.status === 403) {
      // Redirect to the 403 error page
      history.push("/403");
      window.location.reload(); 
    }
    return Promise.reject(
      (error.response && error.response.data) || "There is an error!"
    );
  }
);

export default axiosInt;
