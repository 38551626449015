import { PropsWithChildren } from "react";
import { ModalOptionsProps } from "src/contexts/ModalContext";
import useModal from "src/hooks/private/useModal";
import Button from "../buttons/Button";
import DeleteButton from "../buttons/DeleteButton";
import { AnimatePresence, motion } from "framer-motion";
import OutsideClickHandler from "react-outside-click-handler";

const ModalLayout = ({ children }: PropsWithChildren<{}>) => {
  const { setShowModal } = useModal();

  return (
    <motion.div
      style={{
        backgroundColor: "rgba(0,0,0,0.31)",
      }}
      className="fixed top-0 bottom-0 left-0 right-0 bg-title-text-light flex justify-center items-center z-50 overscroll-none"
      initial={{ opacity: 0 }}
      transition={{ duration: 0.2 }}
      animate={{ opacity: 1 }}
      exit={{ opacity: 0 }}
    >
      <div className="fixed top-0 flex items-center h-full pb-40 justify-center w-full overscroll-none">
        <AnimatePresence>
          <motion.div
            className="bg-white w-10/12 sm:w-8/12 md:w-6/12 lg:w-5/12 xl:w-4/12 2xl:w-3/12 rounded-xl shadow-lg px-2 pb-6 pt-2 drop-shadow-2xl mt-12 overscroll-none"
            initial={{ opacity: 0, scale: 0.5 }}
            transition={{
              opacity: { duration: 0.1 },
              scale: { duration: 0.1 },
            }}
            animate={{ opacity: 1, scale: 1 }}
            exit={{ opacity: 0 }}
          >
            <div
              style={{
                maxHeight: "calc(100vh - 200px)",
              }}
              className="flex flex-col columns-1 justify-center"
            >
              <OutsideClickHandler
                onOutsideClick={() => setShowModal(false)}
                children={children}
              />
            </div>
          </motion.div>
        </AnimatePresence>
      </div>
    </motion.div>
  );
};

const ModalCard = ({
  title,
  body,
  onSubmit,
  showButtons = true,
}: ModalOptionsProps) => {
  const { setShowModal, showModal } = useModal();

  const gradeQuiz = () => {
    return true;
  };
  const handleSubmit = async () => {
    if (gradeQuiz()) {
      setShowModal(false);
      if (typeof onSubmit === "function") await onSubmit();
    }
  };

  const handleCancel = async () => {
    setShowModal(false);
  };

  return (
    <>
      {showModal && (
        <ModalLayout>
          <div className="flex justify-between">
            <div className="w-10"></div>
            <h1 className="text-xxl md:text-xxxl font-semibold select-none text-dark text-primary mt-4">
              {title}
            </h1>
            <DeleteButton onClick={handleCancel} />
          </div>

          <div className="mt-4 w-full px-8 text-center overflow-auto overscroll-none">
            {body}
          </div>

          {showButtons && (
            <div className="flex items-center justify-center">
              <Button
                className="btn btn-ghost text-lg text-black dark:text-white hover:bg-blue-200 hover:dark:bg-gray-900 font-bold mr-1 w-32"
                onClick={handleCancel}
              >
                Cancel
              </Button>

              <Button
                className="btn bg-primary hover:bg-orange-500 text-white dark:text-black text-lg  font-bold ml-1 w-32"
                onClick={handleSubmit}
              >
                Submit
              </Button>
            </div>
          )}
        </ModalLayout>
      )}
    </>
  );
};

export default ModalCard;
