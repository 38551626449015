import React, { useState } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import AthleteCSVRow from "src/interfaces/athleteCSVRow.interface";
import AthletesImportTable from "src/components/athletes/AthletesImportTable";
import useAthletesContext from "src/hooks/private/useAthletesContext";
import { athleteApi } from "src/api";

const AthletesImport = () => {
  const location = useLocation();

  const navigate = useNavigate();

  const { fetchAthletes } = useAthletesContext();

  const data = location.state?.data;

  const [athletes, setAthletes] = useState<AthleteCSVRow[]>(data); // State to manage athletes

  if (!data) {
    return <Navigate to={"/athletes"} />;
  }

  const updateAthlete = (updatedAthlete: AthleteCSVRow) => {
    setAthletes((prevAthletes) =>
      prevAthletes.map((athlete) =>
        athlete.id === updatedAthlete.id ? updatedAthlete : athlete
      )
    );
  };

  const deleteAthlete = (athleteID: number) => {
    setAthletes((prevAthletes) =>
      prevAthletes.filter((athlete) => athlete.id !== athleteID)
    );
  };

  const handleAddToDatabase = async () => {
    try {
      await athleteApi.import(athletes);
    } catch (e: any) {
      console.log(e);
    } finally {
      fetchAthletes();
      navigate("/athletes");
    }
  };

  return (
    <>
      <div>
        <div className="w-full flex items-center justify-between my-6">
          <div>
            <h1 className="text-lg">Edit any data before adding to Database</h1>
          </div>
          <div>
            <button
              className="normal-case bg-back-light border-text-nonfocus border rounded-md px-1 h-7 text-sm btn btn-xs hover:bg-back-light border-1 shadow-sm text-text-light"
              onClick={handleAddToDatabase}
            >
              + Add To Database
            </button>
          </div>
        </div>
        <AthletesImportTable
          athletes={athletes}
          onDelete={deleteAthlete}
          onUpdate={updateAthlete}
        />
      </div>
    </>
  );
};

export default AthletesImport;
