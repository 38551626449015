import axios from "src/utils/axios";
import ApiTokenSupport from "./token.support";
import { PersonalRecordTrends } from "src/interfaces/trends.interface";
import Metric from "src/interfaces/metric.interface";

class TrendsApi extends ApiTokenSupport {
  async getPersonalRecords(startDate?: Date): Promise<PersonalRecordTrends[]> {
    const resp = await axios.post<PersonalRecordTrends[]>(
      `api/trends/personal-records`,
      { startDate },
      this.withAuthorization()
    );
    return resp.data;
  }

  async getProgressData(startDate?: Date): Promise<Metric[]> {
    const resp = await axios.post<Metric[]>(
      `api/trends/progress`,
      { startDate },
      this.withAuthorization()
    );
    return resp.data;
  }


}

export default new TrendsApi();
