import { PropsWithChildren, useState, createContext, useEffect } from "react";
import { useLocation } from "react-router-dom";

export interface Alert {
  display: boolean;
  message: string | null;
  type: string | null;
  duration?: number;
}

interface AlertContextValue {
  alert: Alert;
  setAlert: (alert: Alert) => void;
  clearAlert: () => void;
}

const defaultState = {
  alert: {
    display: false,
    message: null,
    type: null,
    duration: 1000 * 10 
  },
  setAlert: () => {},
  clearAlert: () => {},
};

export const AlertContext = createContext<AlertContextValue>({
  ...defaultState,
});

interface AlertContextProps {}

export const AlertProvider = ({
  children,
}: PropsWithChildren<AlertContextProps>) => {
  const [alert, setAlert] = useState<Alert>(defaultState.alert);

  const clearAlert = () => setAlert({...alert, display: false});

  // Clears the alert if a user navigates to a new page
  const location = useLocation();
  useEffect(() => {
    clearAlert();
  }, [location.pathname]);

  return (
    <AlertContext.Provider value={{ alert, setAlert, clearAlert }}>
      {children}
    </AlertContext.Provider>
  );
};
