import React, { useEffect, useRef, useState } from "react";
import { gymApi } from "src/api";
import useModal from "src/hooks/private/useModal";
import useAlert from "src/hooks/useAlert";
import ModalButton from "../buttons/ModalButton";
import FloatingInput from "../fields/FloatingInput";
import { faFloppyDisk } from "@fortawesome/free-solid-svg-icons";

interface CreateLocationModalProps {
  fetchGyms: () => {};
}

const CreateLocationModal = ({ fetchGyms }: CreateLocationModalProps) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const { setShowModal } = useModal();

  const { setAlert } = useAlert();

  const [gymName, setGymName] = useState<string>("");

  const [isSaving, setIsSaving] = useState<boolean>(false);

  const initialFormErrors: {
    name: string;
  } = {
    name: "",
  };

  interface FormErrors {
    name: string;
  }

  const [formErrors, setFormErrors] = useState<FormErrors>(initialFormErrors);

  const validate: any = (name: string) => {
    const errors: {
      name?: string;
    } = {};
    if (!name.trim()) {
      errors.name = "Gym name is required";
      return errors;
    }
  };

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  const handleSaveSubmit = async () => {
    let errors = validate(gymName);
    if (errors?.name) {
      return setFormErrors(errors);
    } else {
      setFormErrors(initialFormErrors);
      try {
        setIsSaving(true);
        await gymApi.create(gymName);
        fetchGyms();
      } catch (e: any) {
        console.log(e);
        setAlert({
          display: true,
          type: "error",
          message: e.message || "An error has occurred",
        });
      } finally {
        setIsSaving(false);
        setShowModal(false);
        setAlert({
          display: true,
          type: "info",
          message: `${gymName} was successfully created`,
        });
      }
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      handleSaveSubmit();
    }
  };

  return (
    <>
      <div className="py-4 pb-6">
        <FloatingInput
          ref={inputRef}
          value={gymName}
          onChange={(e: any) => setGymName(e.target.value)}
          label="Location Name"
          name="customField"
          handleKeyDown={handleKeyDown}
          error={formErrors.name}
        />
        <div className="mt-4 -mb-4">
          <ModalButton
            isLoading={isSaving}
            onSubmit={handleSaveSubmit}
            label={"Save"}
            loadingLabel="Saving"
            icon={faFloppyDisk}
          />
        </div>
      </div>
    </>
  );
};

export default CreateLocationModal;
