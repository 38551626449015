import React, { useState } from "react";
import useAthletesContext from "src/hooks/private/useAthletesContext";
import { Athlete } from "src/interfaces/athlete.interface";
import { useNavigate } from "react-router-dom";
import { faTrashCan } from "@fortawesome/free-regular-svg-icons";
import useModal from "src/hooks/private/useModal";
import ModalButton from "../buttons/ModalButton";
import useAlert from "src/hooks/useAlert";
import { athleteApi } from "src/api";

interface DeleteAthleteModalProps {
  athlete: Athlete;
}

const DeleteAthleteModal = ({ athlete }: DeleteAthleteModalProps) => {
  const { fetchAthletes } = useAthletesContext();

  const { setShowModal } = useModal();

  const { setAlert } = useAlert();

  const navigate = useNavigate();

  const [isDeleting, setIsDeleting] = useState<boolean>(false);

  const handleDeleteSubmit = async () => {
    setIsDeleting(true);
    try {
      await athleteApi.delete(athlete.id);
      fetchAthletes();
    } catch (e: any) {
      console.log(e);
      setAlert({
        display: true,
        type: "error",
        message: e.message || "An error has occurred",
      });
    } finally {
      setIsDeleting(false);
      setShowModal(false);
      setAlert({
        display: true,
        type: "info",
        message: `${athlete.name} was deleted`,
      });
      navigate("/athletes");
    }
  };
  
  return (
    <>
      <div>
        <p>
          Are you sure you want to delete{" "}
          <span className="font-bold">{athlete?.name}</span> and all their data?
          This action cannot be undone!
        </p>
        <ModalButton
          isLoading={isDeleting}
          onSubmit={handleDeleteSubmit}
          label={"Delete"}
          loadingLabel="Deleting"
          icon={faTrashCan}
          loaderColor={"#F87272"}
          labelColor={"#F87272"}
        />
      </div>
    </>
  );
};

export default DeleteAthleteModal;
