import React from "react";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";
import "src/css/react-datepicker.css";

interface DatePickerProps {
  date: Date;
  onChange: (e: any) => void;
  name?: string;
}

const DatePickerInput = ({ date, onChange, name }: DatePickerProps) => {
  return (
    <>
      <DatePicker selected={date} onChange={onChange} name={name} />
    </>
  );
};

export default DatePickerInput;
