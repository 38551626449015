import React from "react";
import useModal from "src/hooks/private/useModal";
import ImportDataModal from "../modals/ImportDataModal";
import {
  faArrowRightFromBracket,
  faArrowTrendUp,
  faEllipsis,
  faGear,
  faUserPlus,
  faUsers,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import CreateAthleteModal from "../modals/CreateAthleteModal";
import { faFileLines } from "@fortawesome/free-regular-svg-icons";
import ImportAthletesModal from "../modals/ImportAthletesModal";
import useUserContext from "src/hooks/private/useUserContext";
import { useNavigate } from "react-router-dom";
import useAuth from "src/hooks/useAuth";

const ImportFileButton = () => {
  const { openModalWith } = useModal();

  const { logout } = useAuth();

  const { user } = useUserContext();

  const navigate = useNavigate();

  const handleCreateAthlete = () => {
    openModalWith({
      title: "",
      body: <CreateAthleteModal />,
      showButtons: false,
    });
  };

  const handleImportAthletes = () => {
    openModalWith({
      title: "",
      body: <ImportAthletesModal />,
      showButtons: false,
    });
  };

  const handleClick = () => {
    openModalWith({
      title: "",
      body: <ImportDataModal />,
      showButtons: false,
    });
  };

  const handleClickAdmin = () => {
    navigate("/admin");
  };

  const handleTrends = () => {
    navigate("/trends")
  }

  const handleLogout = () => {
    logout();
  };

  return (
    <>
      <div className="dropdown dropdown-end  ">
        <label
          tabIndex={0}
          className="btn m-1 normal-case bg-back-light border-text-nonfocus border rounded-md px-3 h-7 text-sm btn-xs hover:bg-back-light border-1 shadow-sm text-text-light"
        >
          <FontAwesomeIcon icon={faEllipsis} className="" />
        </label>
        <ul
          tabIndex={0}
          className="dropdown-content z-[1] menu p-2 shadow bg-base-100 rounded-box w-52"
        >
          <li>
            <button onClick={handleCreateAthlete}>
              <FontAwesomeIcon icon={faUserPlus} />
              Add Athlete
            </button>
          </li>
          <li>
            <button onClick={handleImportAthletes}>
              <FontAwesomeIcon icon={faUsers} />
              Import Athletes
            </button>
          </li>
          <li>
            <button onClick={handleClick}>
              {" "}
              <FontAwesomeIcon icon={faFileLines} className="pl-1 pr-1" />
              Import Metrics
            </button>
          </li>
          {user?.role === "admin" ||
            (user?.role === "owner" && (
              <li>
                <button onClick={handleClickAdmin}>
                  {" "}
                  <FontAwesomeIcon icon={faGear} className="pl-1 pr-1" />
                  Admin
                </button>
              </li>
            ))}
          <li>
            <button onClick={handleTrends}>
              {" "}
              <FontAwesomeIcon
                icon={faArrowTrendUp}
                className="pl-1 pr-1"
              />
              Trends
            </button>
          </li>
          <li>
            <button onClick={handleLogout}>
              {" "}
              <FontAwesomeIcon
                icon={faArrowRightFromBracket}
                className="pl-1 pr-1"
              />
              Logout
            </button>
          </li>
        </ul>
      </div>
    </>
  );
};

export default ImportFileButton;
