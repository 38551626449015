
import Router from "src/routes";
import { Helmet } from "react-helmet";
import Spinner from "./components/loading/Spinner";

function App() {
  return (
    <>
      <Helmet>
        <title>Genesis Training Academy</title>
      </Helmet>
      <Spinner/>
      <Router />
    </>
  );
}

export default App;