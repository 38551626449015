import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartData,
  TimeScale,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import useViewport from "src/hooks/useViewport";
import "chartjs-adapter-moment";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  TimeScale
);

interface GymChartProps {
  chartData: ChartData<"bar", { x: Date | string; y: number }[], unknown>;
  title: string;
  unit: string;
  timeUnit: "day" | "week" | "month" | "year";
}

const GymChart = ({ chartData, title, unit, timeUnit }: GymChartProps) => {

  const { width } = useViewport();

  const getResponsiveFontSize = (): number => {
    if (width < 640) {
      return 12;
    } else if (width >= 640 && width < 1024) {
      return 18;
    } else {
      return 24;
    }
  };

  const getResponsiveTitleFontSize = (): number => {
    if (width < 640) {
      return 14;
    } else if (width >= 640 && width < 1024) {
      return 20;
    } else {
      return 30;
    }
  };

  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: true,
      },
      title: {
        display: true,
        text: title,
        color: "#163E63",
        padding: 20,
        font: {
          size: getResponsiveTitleFontSize(),
          family: "Arial",
        },
      },
      tooltip: {
        enabled: true,
        mode: 'index' as const,
        intersect: false,
        callbacks: {
          title: function (tooltipItems: any) {
            if (tooltipItems.length) {
              const item = tooltipItems[0];
              return item.label;
            }
            return '';
          },
          label: function (context: any) {
            let label = context.dataset.label || '';
            if (label) {
              label += ': ';
            }
            if (context.parsed.y !== null) {
              label += context.parsed.y + " " + unit;
            }
            return label;
          },
        },
      },
    },
    scales: {
      x: {
        type: "time" as "time",
        time: {
          unit: timeUnit,
          tooltipFormat: "ll",
        },
        grid: {
          display: false,
        },
        ticks: {
          color: "#333",
          maxTicksLimit: 10,
        },
      },
      y: {
        grid: {
          display: true,
          color: "#ddd",
        },
        title: {
          display: true,
          text: `${unit}`,
          color: "#163E63",
          font: {
            size: getResponsiveFontSize(),
          },
        },
        ticks: {
          color: "#333",
        },
      },
    },
  };

  return (
    <>
      <Bar data={chartData} options={chartOptions} />
    </>
  );
};

export default GymChart;
