import React, { useState, useEffect, useRef } from "react";
import { Athlete } from "src/interfaces/athlete.interface";
import Metric from "src/interfaces/metric.interface";
import MetricType from "src/interfaces/metricType.interface";
import DatePickerInput from "../fields/DatePicker";
import { metricApi } from "src/api";
import useModal from "src/hooks/private/useModal";
import { faFloppyDisk } from "@fortawesome/free-regular-svg-icons";
import ModalButton from "../buttons/ModalButton";
import "src/css/hideScrollbar.css";
import useAlert from "src/hooks/useAlert";
import { exerciseIDs } from "src/global/data/exercises";
import useUserContext from "src/hooks/private/useUserContext";

interface AddAthleteDataModalProps {
  athlete: Athlete;
  fetchAthlete: () => Promise<void>;
  fetchMetrics: () => Promise<void>;
}

const AddAthleteDataModal = ({
  athlete,
  fetchAthlete,
  fetchMetrics,
}: AddAthleteDataModalProps) => {
  const [isSaving, setIsSaving] = useState<boolean>(false);
  const inputRef = useRef<HTMLInputElement>(null);
  const { setShowModal } = useModal();
  const { setAlert } = useAlert();
  const { gym, metricTypes, gymMetricPreferences } = useUserContext();

  const initialFormData = {
    date: new Date(),
    exercise_id: 1,
    athlete_id: athlete?.id,
    value: 0,
  };

  const [formData, setFormData] = useState<Metric>(initialFormData);
  const [filteredMetricTypes, setFilteredMetricTypes] = useState<MetricType[]>(
    []
  );
  const [selectedMetricType, setSelectedMetricType] =
    useState<MetricType | null>(null);

  const initialFormErrors: {
    value: string;
  } = {
    value: "",
  };

  interface FormErrors {
    value: string;
  }

  const [formErrors, setFormErrors] = useState<FormErrors>(initialFormErrors);

  const validate: any = (value: number) => {
    const errors: {
      value?: string;
    } = {};
    if (value < 0) {
      errors.value = "Value must be a positive number";
    }
    return errors;
  };

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  useEffect(() => {
    const filteredTypes =
      metricTypes?.filter(
        (type) => type.exercise_id === formData.exercise_id
      ) || [];

    const gymPreference = gymMetricPreferences?.find(
      (pref) => pref.exercise_id === formData.exercise_id
    );

    const defaultMetricType = filteredTypes.find(
      (type) => type.id === gymPreference?.preferred_metric_type_id
    );

    if (defaultMetricType) {
      setFilteredMetricTypes([
        defaultMetricType,
        ...filteredTypes.filter((type) => type.id !== defaultMetricType.id),
      ]);
      setSelectedMetricType(defaultMetricType);
    } else {
      setFilteredMetricTypes(filteredTypes);
      setSelectedMetricType(filteredTypes[0] || null);
    }
  }, [formData.exercise_id, metricTypes, gymMetricPreferences]);

  const handleSaveSubmit = async () => {
    let errors = validate(formData.value);
    if (errors?.value) {
      return setFormErrors(errors);
    } else {
      setFormErrors(initialFormErrors);
      try {
        setIsSaving(true);
        if (selectedMetricType) {
          await metricApi.create({
            metric: formData,
            metricType: selectedMetricType,
          });
        } else {
          setAlert({
            display: true,
            type: "error",
            message: "Please select a metric type",
          });
          return;
        }
      } catch (e: any) {
        console.log(e);
        setAlert({
          display: true,
          type: "error",
          message: e.message || "An error has occurred",
        });
      } finally {
        fetchAthlete();
        fetchMetrics();
        setIsSaving(false);
        setShowModal(false);
        setAlert({
          display: true,
          type: "info",
          message: `Successfully added ${
            exerciseIDs[formData.exercise_id]
          } data for ${athlete.name}`,
        });
      }
    }
  };

  const handleExerciseChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const { value } = e.target;
    setFormData({
      ...formData,
      exercise_id: Number(value),
    });
  };

  const handleDateChange = (date: Date) => {
    setFormData({
      ...formData,
      date: date,
    });
  };

  const handleMetricTypeChange = (e: React.ChangeEvent<HTMLSelectElement>) => {
    const selectedType = filteredMetricTypes?.find(
      (type) => type.id === Number(e.target.value)
    );
    setSelectedMetricType(selectedType || null);
  };

  const handleValueChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const inputValue = e.target.value;
    const numericValue = inputValue === "" ? null : Number(inputValue);

    setFormData({
      ...formData,
      value: numericValue || 0,
    });
  };

  return (
    <>
      <div className="w-full">
        <div className="mb-2">
          <DatePickerInput
            date={formData.date}
            onChange={handleDateChange}
            name={"date"}
          />
        </div>
        <div className="mb-2">
          <select
            className="select select-bordered w-48 font-semibold"
            value={formData.exercise_id}
            name="exercise_id"
            onChange={handleExerciseChange}
          >
            <option disabled>Select Exercise</option>
            {gym?.is_power && <option value={1}>Power</option>}
            {gym?.is_strength && <option value={2}>Strength</option>}
            {gym?.is_acceleration && <option value={3}>Agility</option>}
            {gym?.is_speed && <option value={4}>Speed</option>}
          </select>
        </div>
        <div className="mb-2">
          <select
            className="select select-bordered w-48 font-semibold"
            value={selectedMetricType?.id || ""}
            onChange={handleMetricTypeChange}
          >
            <option disabled value="">
              Select Metric Type
            </option>
            {filteredMetricTypes?.map((type) => (
              <option key={type.id} value={type.id}>
                {type.name} ({type.unit})
              </option>
            ))}
          </select>
        </div>
        <div className="mb-2">
          <input
            type="number"
            name="value"
            onChange={handleValueChange}
            value={formData.value === 0 ? "" : formData.value}
            placeholder="Exercise Value"
            className={
              formErrors.value
                ? "input input-bordered input-error w-48 hide-scrollbar font-medium"
                : "input input-bordered w-48 hide-scrollbar font-medium"
            }
            ref={inputRef}
          />
          {formErrors.value && (
            <p className="text-xs text-error">{formErrors.value}</p>
          )}
        </div>

        <div className="mt-4 mb-4">
          <ModalButton
            isLoading={isSaving}
            onSubmit={handleSaveSubmit}
            label={"Save"}
            loadingLabel="Saving"
            icon={faFloppyDisk}
          />
        </div>
      </div>
    </>
  );
};

export default AddAthleteDataModal;
