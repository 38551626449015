import React from "react";
import {
  faArrowUpLong,
  faDumbbell,
  faTruckFast,
  faGaugeHigh,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { AnimatePresence } from "framer-motion";
import useViewport from "src/hooks/useViewport";
import AthleteCSVRow from "src/interfaces/athleteCSVRow.interface";
import ImportAthleteRow from "./ImportAthleteRow";

interface AthletesImportTableProps {
  athletes: AthleteCSVRow[];
  onUpdate: (data: AthleteCSVRow) => void;
  onDelete: (athleteID: number) => void;
}

const AthletesImportTable = ({
  athletes,
  onUpdate,
  onDelete,
}: AthletesImportTableProps) => {
  const { width, height } = useViewport();

  const isMobile = width < 390;

  const fixedSm = { minWidth: "50px", maxWidth: "50px", width: "50px" };
  const fixedLg = isMobile
    ? { minWidth: "150px", maxWidth: "150px", width: "150px" }
    : { minWidth: "200px", maxWidth: "200px", width: "200px" };

  return (
    <div
      className="overflow-x-auto overflow-y-auto overscroll-y-none max-h-screen"
      style={{ maxHeight: height - 200 }}
    >
      <table className="table relative overscroll-y-none table-fixed">
        <thead className="sticky top-0">
          <tr className="border-b-2 bg-back-light sticky">
            <th></th>
            <th style={fixedSm}>#ID</th>
            <th style={fixedLg}>Name</th>
            <th style={fixedLg}>Date</th>
            <th>
              <FontAwesomeIcon icon={faArrowUpLong} /> Power
            </th>
            <th>
              <FontAwesomeIcon icon={faDumbbell} /> Force Production
            </th>
            <th>
              <FontAwesomeIcon icon={faTruckFast} /> Agility
            </th>
            <th>
              <FontAwesomeIcon icon={faGaugeHigh} /> Speed
            </th>
          </tr>
        </thead>
        <tbody className="border-b-2">
          <AnimatePresence mode="sync">
            {athletes.map((athlete: AthleteCSVRow, index: number) => (
              <ImportAthleteRow key={index} athlete={athlete} onDelete={onDelete} fixedSm={fixedSm} fixedLg={fixedLg} onUpdate={onUpdate} />
            ))}
          </AnimatePresence>
        </tbody>
      </table>
    </div>
  );
};

export default AthletesImportTable;
