import React, { useState, useEffect } from "react";
import { gymApi } from "src/api";
import { GymTable } from "src/interfaces/gym.interface";
import LocationActionsButton from "../buttons/LocationActionsButton";
import useUserContext from "src/hooks/private/useUserContext";

interface GymRowProps {
  gym: GymTable;
  fetchGyms: () => void;
  fetchTrainers: () => void;
}

const GymRow = ({ gym, fetchGyms, fetchTrainers }: GymRowProps) => {
  const [localGym, setLocalGym] = useState(gym);
  const [selectedPreferences, setSelectedPreferences] = useState<{
    [key: number]: number;
  }>({});

  const { metricTypes, gymMetricPreferences, fetchGymMetricPreferences } =
    useUserContext();

  useEffect(() => {
    const initialPreferences: { [key: number]: number } = {};
    gymMetricPreferences?.forEach((preference) => {
      if (preference.gym_id === gym.id) {
        initialPreferences[preference.exercise_id] =
          preference.preferred_metric_type_id;
      }
    });
    setSelectedPreferences(initialPreferences);
  }, [gymMetricPreferences, gym.id]);

  const handleChange = async (e: React.ChangeEvent<HTMLInputElement>) => {
    const { name, checked } = e.target;
    setLocalGym((prev) => ({ ...prev, [name]: checked }));

    try {
      await gymApi.update({
        id: localGym.id,
        key: name,
        value: Boolean(checked),
      });
    } catch (err: any) {
      setLocalGym((prev) => ({ ...prev, [name]: !checked }));
      console.error("Failed to update gym data:", err);
    } finally {
      fetchGyms();
    }
  };

  const handleMetricTypeChange = async (
    exerciseId: number,
    metricTypeId: number
  ) => {
    setSelectedPreferences((prev) => ({
      ...prev,
      [exerciseId]: metricTypeId,
    }));

    try {
      const existingPreference = gymMetricPreferences?.find(
        (preference) =>
          preference.gym_id === gym.id && preference.exercise_id === exerciseId
      );

      if (existingPreference) {
        await gymApi.updateMetricPreference(existingPreference.id, {
          preferred_metric_type_id: metricTypeId,
        });
      }
    } catch (err) {
      console.error("Failed to update gym metric preference:", err);
    } finally {
      fetchGymMetricPreferences();
    }
  };

  return (
    <>
      <tr>
        <td>{localGym.id}</td>
        <td>{gym.name}</td>
        <td>{localGym.numAthletes}</td>
        <td>{localGym.numUsers}</td>
        <td>
          <label className="label cursor-pointer">
            <input
              type="checkbox"
              name="is_power"
              className="toggle toggle-accent"
              checked={localGym.is_power}
              onChange={handleChange}
            />
          </label>
        </td>
        <td>
          <label className="label cursor-pointer">
            <input
              type="checkbox"
              name="is_strength"
              className="toggle toggle-accent"
              checked={localGym.is_strength}
              onChange={handleChange}
            />
          </label>
        </td>
        <td>
          <label className="label cursor-pointer">
            <input
              type="checkbox"
              name="is_acceleration"
              className="toggle toggle-accent"
              checked={localGym.is_acceleration}
              onChange={handleChange}
            />
          </label>
        </td>
        <td>
          <label className="label cursor-pointer">
            <input
              type="checkbox"
              name="is_speed"
              className="toggle toggle-accent"
              checked={localGym.is_speed}
              onChange={handleChange}
            />
          </label>
        </td>
        <td>
          <LocationActionsButton
            gym={localGym}
            fetchGyms={fetchGyms}
            fetchTrainers={fetchTrainers}
          />
        </td>
      </tr>
      {/* Secondary row for metric type selection */}
      <tr>
        <td colSpan={4}>
          <div className="flex items-center justify-end">
            Preferred Metric Unit
          </div>
        </td>
        {[1, 2, 3, 4].map((exerciseId) => (
          <td key={exerciseId}>
            <select
              className="select select-bordered w-[115px] font-semibold"
              value={selectedPreferences[exerciseId] || ""}
              onChange={(e) =>
                handleMetricTypeChange(exerciseId, parseInt(e.target.value))
              }
            >
              {metricTypes
                ?.filter((type) => type.exercise_id === exerciseId)
                .map((type) => (
                  <option key={type.id} value={type.id}>
                    {type.name}
                  </option>
                ))}
            </select>
          </td>
        ))}
        <td></td>
      </tr>
    </>
  );
};

export default GymRow;
