import React from "react";
import { AthletePlusMetric } from "src/interfaces/athlete.interface";
import { useNavigate } from "react-router-dom";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faArrowUpLong,
  faDumbbell,
  faGaugeHigh,
  faTruckFast,
} from "@fortawesome/free-solid-svg-icons";
import useViewport from "src/hooks/useViewport";
import { motion, AnimatePresence } from "framer-motion";
import useUserContext from "src/hooks/private/useUserContext";
import { metricConversionMap } from "src/utils/conversionFunctions";

interface AthletesTableProps {
  athletes: AthletePlusMetric[];
}

const AthletesTable = ({ athletes }: AthletesTableProps) => {
  const navigate = useNavigate();
  const { width, height } = useViewport();
  const { gym, gymMetricPreferences, metricTypes } = useUserContext();

  const expand = width > 830;
  const isMobile = width < 410;
  const showID = width > 420;

  const fixedSm = { minWidth: "50px", maxWidth: "50px", width: "50px" };
  const fixedMed = expand
    ? { minWidth: "140px", maxWidth: "140px", width: "140px" }
    : { minWidth: "50px", maxWidth: "50px", width: "50px" };
  const fixedLg = isMobile
    ? { minWidth: "150px", maxWidth: "150px", width: "150px" }
    : { minWidth: "200px", maxWidth: "200px", width: "200px" };

  const convertMetric = (
    value: number | undefined,
    exerciseId: number
  ): { value: number | string; unit: string } => {
    if (!value) return { value: "-", unit: "" };

    const preference = gymMetricPreferences?.find(
      (pref) => pref.exercise_id === exerciseId
    );
    const metricType = metricTypes?.find(
      (type) => type.id === preference?.preferred_metric_type_id
    );

    if (metricType) {
      // Check if there's a conversion function for the given metric_type_id
      const conversion = metricConversionMap[metricType.id];
      if (conversion) {
        return {
          value: conversion.fromMPH(value).toFixed(2),
          unit: metricType.unit,
        };
      }

      // No conversion needed
      return { value, unit: metricType.unit || "" };
    }

    // Fallback if no metric type is found
    return { value, unit: "" };
  };

  return (
    <>
      <div
        className="overflow-x-auto overflow-y-auto overscroll-y-none w-full max-h-screen"
        style={{ maxHeight: height - 200 }}
      >
        <table className="table relative overscroll-y-none ">
          <thead className="sticky top-0">
            <tr className="border-b-2 bg-back-light sticky">
              {showID && (
                <th
                  className=" bg-back-light sticky border-b-2"
                  style={fixedSm}
                >
                  #ID
                </th>
              )}
              <th className="normal-case bg-back-light sticky" style={fixedLg}>
                Name
              </th>
              {gym?.is_power && (
                <th
                  className="normal-case bg-back-light sticky"
                  style={fixedMed}
                >
                  <FontAwesomeIcon icon={faArrowUpLong} />
                  {expand && " Power"}
                </th>
              )}
              {gym?.is_strength && (
                <th
                  className="normal-case bg-back-light sticky"
                  style={fixedMed}
                >
                  <FontAwesomeIcon icon={faDumbbell} />
                  {expand && "Strength"}
                </th>
              )}
              {gym?.is_acceleration && (
                <th
                  className="normal-case bg-back-light sticky"
                  style={fixedMed}
                >
                  <FontAwesomeIcon icon={faTruckFast} />
                  {expand && " Agility"}
                </th>
              )}
              {gym?.is_speed && (
                <th
                  className="normal-case bg-back-light sticky"
                  style={fixedMed}
                >
                  <FontAwesomeIcon icon={faGaugeHigh} />
                  {expand && " Speed"}
                </th>
              )}
            </tr>
          </thead>
          <tbody className="border-b-2">
            <AnimatePresence mode="sync">
              {athletes.map((athlete: AthletePlusMetric) => (
                <motion.tr
                  layout
                  key={athlete.id}
                  onClick={() =>
                    navigate(`/athletes/${athlete.id}`, {
                      state: { fromPage: "athletes" },
                    })
                  }
                  className="cursor-pointer border-b-1"
                  initial={{ opacity: 0 }}
                  animate={{ opacity: 1 }}
                  transition={{
                    opacity: { duration: 0.25 },
                  }}
                  exit={{ opacity: 0 }}
                >
                  {showID && (
                    <td className="text-text-light" style={fixedSm}>
                      {athlete.id}
                    </td>
                  )}
                  <td className="font-medium text-black" style={fixedLg}>
                    {athlete.name}
                  </td>
                  {gym?.is_power && (
                    <td
                      className="text-text-light font-medium"
                      style={fixedMed}
                    >
                      {athlete.power
                        ? convertMetric(athlete.power, 1).value
                        : "-"}
                      {!isMobile && (
                        <span className="text-zinc-500">
                          {" "}
                          {convertMetric(athlete.power, 1).unit}
                        </span>
                      )}
                    </td>
                  )}
                  {gym?.is_strength && (
                    <td
                      className="text-text-light font-medium"
                      style={fixedMed}
                    >
                      {athlete.strength
                        ? convertMetric(athlete.strength, 2).value
                        : "-"}
                      {!isMobile && (
                        <span className="text-zinc-500">
                          {" "}
                          {convertMetric(athlete.strength, 2).unit}
                        </span>
                      )}
                    </td>
                  )}
                  {gym?.is_acceleration && (
                    <td
                      className="text-text-light font-medium"
                      style={fixedMed}
                    >
                      {athlete.acceleration
                        ? convertMetric(athlete.acceleration, 3).value
                        : "-"}
                      {!isMobile && (
                        <span className="text-zinc-500">
                          {" "}
                          {convertMetric(athlete.acceleration, 3).unit}
                        </span>
                      )}
                    </td>
                  )}
                  {gym?.is_speed && (
                    <td
                      className="text-text-light font-medium"
                      style={fixedMed}
                    >
                      {athlete.speed
                        ? convertMetric(athlete.speed, 4).value
                        : "-"}
                      {!isMobile && (
                        <span className="text-zinc-500">
                          {" "}
                          {convertMetric(athlete.speed, 4).unit}
                        </span>
                      )}
                    </td>
                  )}
                </motion.tr>
              ))}
            </AnimatePresence>
          </tbody>
        </table>
      </div>
    </>
  );
};

export default AthletesTable;
