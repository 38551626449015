import { useEffect } from "react";
import classNames from "classnames";
import type { Alert } from "src/contexts/AlertContext";
import useAlert from "src/hooks/useAlert";
import {
  faCircleCheck,
  faCircleExclamation,
  faCircleInfo,
  faCircleXmark,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface AlertErrorProps {
  alert: Alert;
  onClose?: () => void;
  color?: {
    bg?: string;
    text?: string;
  };
}

const AlertComponent = ({ alert, color, onClose }: AlertErrorProps) => {
  const { clearAlert } = useAlert();

  let className = {
    bg: "#f3f4f6",
    text: "#3a4453",
  };

  switch (alert.type) {
    case "info":
      className = { ...className, bg: "bg-[#3abff88d] border border-info" };
      className = { ...className, text: "text-text-dark]" };
      break;
    case "error":
      className = { ...className, bg: "bg-[#ed606078] border border-error" };
      className = { ...className, text: "text-text-dark" };
      break;
    case "success":
      className = { ...className, bg: "bg-[#36d39976] border border-success" };
      className = { ...className, text: "text-text-dark" };
      break;
    case "warning":
      className = { ...className, bg: "bg-[#fbbe2382] border border-warning" };
      className = { ...className, text: "text-text-dark" };
      break;
  }

  useEffect(() => {
    // Display the alert for 5 seconds then close
    const timeoutId = setTimeout(() => {
      if (alert.display) clearAlert();
    }, alert.duration || 1000 * 8);

    return () => clearTimeout(timeoutId);
  }, [alert, clearAlert]);

  if (!alert.display) {
    return null;
  }

  const alertIcon = () => {
    switch (alert.type) {
      case "info":
        return faCircleInfo;
      case "success":
        return faCircleCheck;
      case "warning":
        return faCircleExclamation;
      case "error":
        return faCircleXmark;
      default:
        return faCircleInfo;
    }
  };

  return (
    <div
      className={classNames(["flex p-2 mb-3 w-full rounded-lg", color?.bg], {
        [className.bg]: !color?.bg,
      })}
      role="alert"
    >
      <div className="flex items-center justify-center">
        <FontAwesomeIcon icon={alertIcon()} />
      </div>

      {/* <svg
        aria-hidden="true"
        className={classNames(
          ["flex-shrink-0 w-5 h-5 text-black", color?.text],
          {
            [className.text]: !color?.text,
          }
        )}
        fill="currentColor"
        viewBox="0 0 20 20"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          fillRule="evenodd"
          d="M18 10a8 8 0 11-16 0 8 8 0 0116 0zm-7-4a1 1 0 11-2 0 1 1 0 012 0zM9 9a1 1 0 000 2v3a1 1 0 001 1h1a1 1 0 100-2v-3a1 1 0 00-1-1H9z"
          clipRule="evenodd"
        ></path>
      </svg> */}
      <span className="sr-only">Info</span>
      <div
        className={classNames(
          ["ml-3 text-sm font-medium text-black", color?.text],
          {
            [className.text]: !color?.text,
          }
        )}
      >
        {alert.message}
      </div>
      {Boolean(onClose) && (
        <button
          type="button"
          onClick={onClose}
          className=" -mx-1.5 -my-1.5 ml-auto inline-flex h-8 w-8 rounded-lg p-1.5 "
          data-dismiss-target="#alert-2"
          aria-label="Close"
        >
          <span className="sr-only">Close</span>

          <svg
            className={classNames(["h-5 w-5", color?.text], {
              [className.text]: !color?.text,
            })}
            fill="#000"
            viewBox="0 0 20 20"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fillRule="evenodd"
              d="M4.293 4.293a1 1 0 011.414 0L10 8.586l4.293-4.293a1 1 0 111.414 1.414L11.414 10l4.293 4.293a1 1 0 01-1.414 1.414L10 11.414l-4.293 4.293a1 1 0 01-1.414-1.414L8.586 10 4.293 5.707a1 1 0 010-1.414z"
              clipRule="evenodd"
            ></path>
          </svg>
        </button>
      )}
    </div>
  );
};

export default AlertComponent;
