import React, { useState } from "react";
import AthleteCSVRow from "src/interfaces/athleteCSVRow.interface";
import { motion } from "framer-motion";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

interface ImportAthleteRowProps {
  athlete: AthleteCSVRow;
  onDelete: (athleteId: number) => void;
  onUpdate: (athlete: AthleteCSVRow) => void;
  fixedSm: {};
  fixedLg: {};
}

const ImportAthleteRow = ({
  athlete,
  onDelete,
  onUpdate,
  fixedSm,
  fixedLg,
}: ImportAthleteRowProps) => {
  const [isHoveringRow, setIsHoveringRow] = useState<boolean>(false);

  const [editableAthlete, setEditableAthlete] =
    useState<AthleteCSVRow>(athlete);
  const [editMode, setEditMode] = useState<{ [key: string]: boolean }>({});

  const handleEdit = (field: string) => {
    setEditMode({ ...editMode, [field]: true });
  };

  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement>,
    field: string
  ) => {
    setEditableAthlete({ ...editableAthlete, [field]: e.target.value });
  };

  const handleBlur = (field: string) => {
    setEditMode({ ...editMode, [field]: false });
    onUpdate(editableAthlete);
  };

  const handleKeyDown = (e: React.KeyboardEvent, field: string) => {
    if (e.key === "Enter") {
      setEditMode({ ...editMode, [field]: false });
      onUpdate(editableAthlete);
    }
  };

  return (
    <>
      <motion.tr
        layout
        key={athlete.id}
        className="cursor-pointer border-b-1"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{
          opacity: { duration: 0.25 },
        }}
        exit={{ opacity: 0 }}
        onHoverStart={() => setIsHoveringRow(true)}
        onHoverEnd={() => setIsHoveringRow(false)}
      >
        <td className="py-0 my-0">
          <button
            className={
              isHoveringRow
                ? "btn btn-square btn-ghost h-6 w-6 px-6 text-black hover:text-error"
                : "invisible h-6 w-6 px-6"
            }
            onClick={() => onDelete(athlete.id)}
          >
            <FontAwesomeIcon
              icon={faTrash}
              className={isHoveringRow ? "" : "invisible"}
            />
          </button>
        </td>
        <td style={fixedSm}>{Number.isNaN(athlete.id) ? "New" : athlete.id}</td>
        {Object.entries(editableAthlete).map(([key, value]) => {
          if (key === "id") return null; // Skip ID field
          return (
            <td
              key={key}
              style={key === "name" ? fixedLg : {}}
              onClick={() => handleEdit(key)}
            >
              {editMode[key] ? (
                <input
                  type="text"
                  value={value}
                  onChange={(e) => handleChange(e, key)}
                  onBlur={() => handleBlur(key)}
                  onKeyDown={(e) => handleKeyDown(e, key)}
                  autoFocus
                  className=""
                  style={fixedSm}
                />
              ) : (
                <span>{value}</span>
              )}
            </td>
          );
        })}
      </motion.tr>
    </>
  );
};

export default ImportAthleteRow;
