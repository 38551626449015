import React, {
  PropsWithChildren,
  useCallback,
  useEffect,
  useState,
} from "react";
import { athleteApi } from "src/api";
import useErrorHandler from "src/hooks/useErrorHandler";
import { AthletePlusMetric } from "src/interfaces/athlete.interface";

interface AthletesContextInterface {
  athletes: AthletePlusMetric[];
  fetchAthletes: () => void;
}

const AthleteContextDefaults: AthletesContextInterface = {
  athletes: [],
  fetchAthletes: () => {},
};

export const AthleteContext = React.createContext<AthletesContextInterface>(
  AthleteContextDefaults
);

interface AthleteProviderProps {}

export const AthleteProvider = ({
  children,
}: PropsWithChildren<AthleteProviderProps>) => {
  const [athletes, setAthletes] = useState<AthletePlusMetric[]>([]);

  const { handleError } = useErrorHandler();

  const fetchAthletes = useCallback(async () => {
    try {
      const athletes = await athleteApi.getAll();
      setAthletes(athletes);
    } catch (e) {
      handleError(e);
    }
  }, [handleError]);

  useEffect(() => {
    fetchAthletes();
  }, [fetchAthletes]);

  return (
    <AthleteContext.Provider
      value={{
        athletes,
        fetchAthletes,
      }}
    >
      {children}
    </AthleteContext.Provider>
  );
};
