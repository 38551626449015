import { faEllipsis, faLocationDot } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import useModal from "src/hooks/private/useModal";
import CreateLocationModal from "../modals/CreateLocationModal";

interface LocationButtonProps {
  fetchGyms: () => {};
}

const LocationButton = ({ fetchGyms }: LocationButtonProps) => {
  const { openModalWith } = useModal();

  const handleCreateLocation = () => {
    openModalWith({
      title: "",
      body: <CreateLocationModal fetchGyms={fetchGyms} />,
      showButtons: false,
    });
  };

  return (
    <>
      <div className="dropdown dropdown-end  ">
        <label
          tabIndex={0}
          className="btn m-1 normal-case bg-back-light border-text-nonfocus border rounded-md px-3 h-7 text-sm btn-xs hover:bg-back-light border-1 shadow-sm text-text-light"
        >
          <FontAwesomeIcon icon={faEllipsis} className="" />
        </label>
        <ul
          tabIndex={0}
          className="dropdown-content z-[1] menu p-2 shadow bg-base-100 rounded-box w-52"
        >
          <li>
            <button onClick={handleCreateLocation}>
              <FontAwesomeIcon icon={faLocationDot} />
              Add Location
            </button>
          </li>
        </ul>
      </div>
    </>
  );
};

export default LocationButton;
