import React, { useEffect, useRef, useState } from "react";
import ViewSlider from "./ViewSlider";
import ChartType from "src/types/ChartTypes";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartData,
  PointElement,
  LineElement,
  TimeScale,
} from "chart.js";
import { Line } from "react-chartjs-2";
import useViewport from "src/hooks/useViewport";
import ListTable from "./ListTable";
import Metric from "src/interfaces/metric.interface";
import { motion, AnimatePresence } from "framer-motion";
import "chartjs-adapter-moment";

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
  TimeScale
);

interface ChartProps {
  chartData: ChartData<"line", { x: Date | string; y: number }[], unknown>;
  title: string;
  unit: string;
  metrics: Metric[];
  labels: string[];
  fetchMetrics: () => void;
  invertChart?: boolean;
}

const Chart = ({
  chartData,
  title,
  unit,
  metrics,
  labels,
  fetchMetrics,
  invertChart = false,
}: ChartProps) => {
  const [viewType, setViewType] = useState<ChartType>("graph");
  const [chartHeight, setChartHeight] = useState<number | undefined>(undefined);

  const chartRef = useRef<HTMLDivElement>(null);

  const handleChartChange = (type: ChartType) => {
    setViewType(type);
  };

  const { width } = useViewport();

  useEffect(() => {
    if (chartRef.current) {
      setChartHeight(chartRef.current.offsetHeight);
    }
  }, [viewType]);

  const getResponsiveFontSize = (): number => {
    if (width < 640) {
      // Mobile
      return 12;
    } else if (width >= 640 && width < 1024) {
      // Tablet
      return 18;
    } else {
      // Desktop
      return 24;
    }
  };

  const getResponsiveTitleFontSize = (): number => {
    if (width < 640) {
      // Mobile
      return 14;
    } else if (width >= 640 && width < 1024) {
      // Tablet
      return 20;
    } else {
      // Desktop
      return 30;
    }
  };

  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: true,
        text: title,
        color: "#163E63",
        padding: 20,
        font: {
          size: getResponsiveTitleFontSize(),
          family: "Arial",
        },
      },
      elements: {
        line: {
          tension: 0.3, // Adjust the tension to create smoother curves
        },
        point: {
          radius: 5, // Adjust the radius of the points
          hoverRadius: 7, // Adjust the hover radius of the points
          // Specify styles for different types of points if needed
        },
      },
      tooltip: {
        enabled: true,
        mode: 'index' as const,
        intersect: false,
        callbacks: {
          title: function (tooltipItems: any) {
            // Assuming the first item in the array represents the needed information
            if (tooltipItems.length) {
              const item = tooltipItems[0];
              // You need to ensure that the label is a string
              // If the label is not a string, you may need to convert it
              return item.label;
            }
            return '';
          },
          label: function (context: any) {
            // Format the label for the tooltip
            let label = context.dataset.label || '';
            if (label) {
              label += ': ';
            }
            if (context.parsed.y !== null) {
              label += context.parsed.y + " " + unit; // Customize unit as needed
            }
            // Add any additional information you want to show here
            return label;
          },
        },
      },
    },
    scales: {
      x: {
        type: "time" as "time",
        time: {
          unit: "day" as "day", // or 'month', 'year', etc., depending on your data
          tooltipFormat: "ll", // format for the tooltip (using moment.js formatting)
        },
        grid: {
          display: false,
        },
        ticks: {
          color: "#333",
          maxTicksLimit: 10,
          // callback: function (val: any, index: number): any {
          //   // Convert the timestamp to a formatted date string
          //   const label = moment(val).format("MMM DD, YYYY");

          //   if (labels.includes(label)) {
          //     return label;
          //   }
          //   return null; // Hide non-matching labels
          // },
        },
      },
      y: {
        reverse: invertChart,
        grid: {
          display: true,
          color: "#ddd",
        },
        title: {
          display: true,
          text: `(${unit})`,
          color: "#163E63",
          font: {
            size: getResponsiveFontSize(),
          },
        },
        ticks: {
          color: "#333",
        },
      },
    },
  };

  const slideAnimation = {
    exit: (direction: number) => ({
      x: direction > 0 ? 100 : -100,
      opacity: 0,
      transition: { duration: 0.2 },
    }),
    enter: {
      x: 0,
      opacity: 1,
      transition: { delay: 0.2, duration: 0.3 },
    },
  };

  const direction = viewType === "graph" ? 1 : -1;

  return (
    <>
      <div className="w-full" style={{ height: chartHeight }}>
        <ViewSlider type={viewType} handleChange={handleChartChange} />
        <AnimatePresence custom={direction} onExitComplete={() => {}}>
          {viewType === "graph" ? (
            <motion.div
              key="lineChart"
              custom={direction}
              ref={chartRef}
              initial="exit"
              animate="enter"
              exit="exit"
              variants={slideAnimation}
              className="w-full flex items-center justify-center"
            >
              <Line data={chartData} options={chartOptions} />
            </motion.div>
          ) : (
            <motion.div
              key="listTable"
              custom={direction}
              initial="exit"
              animate="enter"
              exit="exit"
              variants={slideAnimation}
              className="w-full flex items-center justify-center"
              style={{ height: chartHeight }}
            >
              <ListTable
                metrics={metrics}
                unit={unit}
                fetchMetrics={fetchMetrics}
              />
            </motion.div>
          )}
        </AnimatePresence>
      </div>
    </>
  );
};

export default Chart;
