import React from "react";
import { Athlete } from "src/interfaces/athlete.interface";
import NamePlate from "./NamePlate";
import PDFChart from "../charts/PDFChart";
import { ChartData } from "chart.js";
import logo from "src/assets/GenesisLogo_BlackShadow.png";
import saintsLogo from "src/assets/saints.png";
import useUserContext from "src/hooks/private/useUserContext";
import gspLogo from "src/assets/GenesisLogo_BlackShadow.png";
import pptLogo from "src/assets/PPT.png";
import jungleLogo from "src/assets/jungle.png";
import battiLogo from "src/assets/batti.jpg";

interface PDFOutputHTMLProps {
  athlete: Athlete;
  verticalData: ChartData<"line", { x: Date | string; y: number }[], unknown>;
  strengthData: ChartData<"line", { x: Date | string; y: number }[], unknown>;
  accelerationData: ChartData<
    "line",
    { x: Date | string; y: number }[],
    unknown
  >;
  speedData: ChartData<"line", { x: Date | string; y: number }[], unknown>;
}

const PDFOutputHTML = ({
  athlete,
  verticalData,
  strengthData,
  accelerationData,
  speedData,
}: PDFOutputHTMLProps) => {
  const { gym } = useUserContext();

  const { user } = useUserContext();

  return (
    <>
      <div
        id="pdf"
        className="flex items-center justify-between bg-white z-10 top-42 flex-col w-full relative"
      >
        <div className="absolute top-0 left-0 flex items-start justify-start w-full gap-4">
          {user?.gym_id === 1 && (
            <img
              src={gspLogo}
              alt="Genesis Sports Performance"
              className="w-[35px]"
            />
          )}
          {user?.gym_id === 5 && (
            <img
              src={pptLogo}
              alt="Premier Performance Training"
              className="w-[275px] py-0"
            />
          )}
          {user?.gym_id === 6 && (
            <img
              src={jungleLogo}
              alt="Jungle Athletics"
              className="w-[300px]"
            />
          )}
          {user?.gym_id === 7 && (
            <div className="py-4">
              <img src={battiLogo} alt="Batti Gym" className="w-[290px]" />
            </div>
          )}
          {user?.gym_id === 8 && (
            <div className="py-4">
              <img
                src={saintsLogo}
                alt="Iron Saints Athletic Systems"
                className="w-[65px]"
              />
            </div>
          )}
          {user?.gym_id === 1 && (
            <h1 className="absolute -top-[12px] left-[40px] text-[26px] font-gta text-[#050707]">
              Genesis Sports Performance
            </h1>
          )}
          {/* {user?.gym_id === 8 && (
            <h1 className="absolute -top-[12px] left-[40px] text-[26px] font-gta text-[#050707]">
              Iron Saints Athletic Systems
            </h1>
          )} */}
        </div>
        <div className="flex items-center justify-center mt-12 mb-8 bg-back-light w-full px-4">
          <NamePlate name={athlete?.name || ""} />
        </div>
        <div className="grid grid-cols-2 grid-rows-2 w-[1000px] gap-4">
          <div className="col-span-1 row-span-1">
            {verticalData && gym?.is_power && (
              <PDFChart
                chartData={verticalData}
                title={"Power"}
                unit={"inches"}
              />
            )}
          </div>
          <div className="col-span-1 row-span-1">
            {strengthData && gym?.is_strength && (
              <PDFChart
                chartData={strengthData}
                title={"Strength"}
                unit={"lbs"}
              />
            )}
          </div>
          <div className="col-span-1 row-span-1">
            {accelerationData && gym?.is_acceleration && (
              <PDFChart
                chartData={accelerationData}
                title={"Agility"}
                unit={"seconds"}
                invertChart={true}
              />
            )}
          </div>
          <div className="col-span-1 row-span-1">
            {speedData && gym?.is_speed && (
              <PDFChart
                chartData={speedData}
                title={"Speed"}
                unit={"mph"}
                invertChart={false}
              />
            )}
          </div>
        </div>
      </div>
    </>
  );
};

export default PDFOutputHTML;
