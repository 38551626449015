import React, { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import queryString from 'query-string';
import AthletesTable from "src/components/athletes/AthletesTable";
import SearchAthletes from "src/components/athletes/SearchAthletes";
import ImportFileButton from "src/components/buttons/ImportFileButton";
import useAthletesContext from "src/hooks/private/useAthletesContext";
import { AthletePlusMetric } from "src/interfaces/athlete.interface";

interface AthletesProps {
  props?: any;
}

const Athletes = ({ props }: AthletesProps) => {
  const { athletes, fetchAthletes } = useAthletesContext();
  const navigate = useNavigate();
  const location = useLocation();

  const { search } = queryString.parse(location.search);
  const [searchForm, setSearchForm] = useState<string>(search as string || "");
  const [filteredAthletes, setFilteredAthletes] = useState<AthletePlusMetric[]>([]);

  useEffect(() => {
    fetchAthletes()
  }, [fetchAthletes])

  useEffect(() => {
    const query = searchForm ? `?search=${encodeURIComponent(searchForm)}` : '';
    navigate(query, { replace: true });
  }, [searchForm, navigate]);

  useEffect(() => {
    setSearchForm(search as string || '');
  }, [search]);

  useEffect(() => {
    const newFilteredAthletes = athletes.filter((athlete: AthletePlusMetric) =>
      athlete.name.toLowerCase().includes(searchForm.toLowerCase())
    );
    setFilteredAthletes(newFilteredAthletes);
  }, [searchForm, athletes]);

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = e.target;
    setSearchForm(value);
  };

  return (
    <>
      <div className="flex items-center justify-between py-2 fixed bg-white z-30 top-42 flex-col">
        <div className="flex items-center justify-between px:2 sm:px-0 gap-2 py-3 w-full">
          <SearchAthletes value={searchForm} handleChange={handleChange} />
          <ImportFileButton />
        </div>
        <AthletesTable athletes={filteredAthletes} />
      </div>
      <div className="pt-16 overflow-y-auto"></div>
    </>
  );
};

export default Athletes;
