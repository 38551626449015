import React from "react";
import {
  faEllipsis,
  faPenToSquare,
  faUserPlus,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import EditLocationModal from "../modals/EditLocationModal";
import useModal from "src/hooks/private/useModal";
import { GymTable } from "src/interfaces/gym.interface";
import AddUserModal from "../modals/AddUserModal";

interface LocationActionsButtonProps {
  gym: GymTable;
  fetchGyms: () => void;
  fetchTrainers: () => void;
}

const LocationActionsButton = ({
  gym,
  fetchGyms,
  fetchTrainers,
}: LocationActionsButtonProps) => {
  const { openModalWith } = useModal();

  const handleEditLocation = () => {
    openModalWith({
      title: "",
      body: <EditLocationModal gym={gym} fetchGyms={fetchGyms} />,
      showButtons: false,
    });
  };

  const handleAddUser = () => {
    openModalWith({
      title: "",
      body: <AddUserModal gym={gym} fetchGyms={fetchGyms} fetchTrainers={fetchTrainers} />,
      showButtons: false,
    });
  };

  return (
    <>
      <div className="dropdown dropdown-end  ">
        <label
          tabIndex={0}
          className="btn m-1 normal-case bg-back-light border-text-nonfocus border rounded-md px-3 h-7 text-sm btn-xs hover:bg-back-light border-1 shadow-sm text-text-light"
        >
          <FontAwesomeIcon icon={faEllipsis} className="" />
        </label>
        <ul
          tabIndex={0}
          className="dropdown-content z-[1] menu p-2 shadow bg-base-100 rounded-box w-52"
        >
          <li>
            <button onClick={handleEditLocation}>
              <FontAwesomeIcon icon={faPenToSquare} />
              Edit Location
            </button>
          </li>
          <li>
            <button onClick={handleAddUser}>
              <FontAwesomeIcon icon={faUserPlus} />
              Add Trainer
            </button>
          </li>
        </ul>
      </div>
    </>
  );
};

export default LocationActionsButton;
