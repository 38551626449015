const convertSecondsToMPH = (seconds: number): number => {
  const mph = (10 / seconds) * 2.045;
  return Number(mph.toFixed(2));
};

const convertMPHToSeconds = (mph: number): number => {
  const seconds = 20.45 / mph;
  return Number(seconds.toFixed(2));
};


type ConversionFunction = (value: number) => number;

const metricConversionMap: { [key: number]: { toMPH: ConversionFunction; fromMPH: ConversionFunction } } = {
  4: {
    toMPH: convertSecondsToMPH,
    fromMPH: convertMPHToSeconds,
  },
  // Add other metric types here with their respective IDs
};

export { metricConversionMap  };