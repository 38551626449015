import React from "react";
import { motion } from "framer-motion";
interface ActionButtonProps {
  label: string;
  onClick: () => void;
}

const ActionButton = ({ label, onClick }: ActionButtonProps) => {
  return (
    <>
      <motion.button
        layout
        className="normal-case bg-back-light border-text-nonfocus border rounded-md px-1 h-7 text-sm btn btn-xs hover:bg-back-light border-1 shadow-sm text-text-light"
        onClick={onClick}
      >
        {label}
      </motion.button>
    </>
  );
};

export default ActionButton;
