import React, { useState } from "react";
import AthleteCSVRow from "src/interfaces/athleteCSVRow.interface";
import { AnimatePresence, motion } from "framer-motion";
import Papa from "papaparse";
import * as XLSX from "xlsx";
import { formatDateForDB } from "src/helpers/formatDateForDB";
import { useNavigate } from "react-router-dom";
import useModal from "src/hooks/private/useModal";
import ModalButton from "../buttons/ModalButton";
import { faFileLines } from "@fortawesome/free-regular-svg-icons";
import convertSecondsToMPH from "src/helpers/convertSecondsToMPH";
import { athleteApi } from "src/api";

interface DataFileRow {
  // Acceleration: string;
  // "Force Production": string;
  AthleteID: string;
  Date: string;
  Name: string;
  Power: string;
  Speed: string;
}

const ImportDataModal = () => {
  const [csvFile, setCsvFile] = useState<File>();

  const [error, setError] = useState<null | string>(null);

  const navigate = useNavigate();

  const [isImporting, setIsImporting] = useState<boolean>(false);

  const { setShowModal } = useModal();

  const handleChangeFile = ({
    target: { files },
  }: React.ChangeEvent<HTMLInputElement>) => {
    setError(null);

    if (!files) {
      return;
    }

    const fileType = files[0].type;
    const validTypes = [
      "text/csv",
      "application/vnd.openxmlformats-officedocument.spreadsheetml.sheet",
      "application/vnd.ms-excel",
    ];

    if (!validTypes.includes(fileType)) {
      setError("File must be .csv or .xlsx/.xls");
      return;
    }

    setCsvFile(files[0]);
  };

  const readFile = () => {
    if (!csvFile) return setError("Enter a valid file");
    setIsImporting(true);

    const reader = new FileReader();

    reader.onload = async ({ target }: any) => {
      const fileType = csvFile.type;
      let parsedData: any[];

      if (fileType === "text/csv") {
        parsedData = Papa.parse(target.result, { header: true }).data;
      } else {
        const workbook = XLSX.read(target.result, {
          type: "binary",
          cellDates: true,
          cellNF: false,
          cellText: false,
        });
        const sheetName = workbook.SheetNames[0];
        const sheet = workbook.Sheets[sheetName];
        parsedData = XLSX.utils.sheet_to_json(sheet);
      }

      const cleanedData: DataFileRow[] = parsedData.filter((row: DataFileRow) =>
        Object.values(row).every((value) => value.toString().trim() !== "")
      );

      const dataReady: AthleteCSVRow[] = cleanedData.map((row: DataFileRow) => {
        // Convert Excel date serial number to a JavaScript date object
        const dateValue =
          typeof row.Date === "number"
            ? XLSX.SSF.parse_date_code(row.Date)
            : new Date(row.Date);

        return {
          id: Number(row.AthleteID),
          name: row.Name,
          // If the Date is a number, format it, otherwise pass it directly
          date: formatDateForDB(
            typeof row.Date === "number"
              ? new Date(dateValue.y, dateValue.m - 1, dateValue.d)
              : new Date(row.Date)
          ),
          // vertical: Number(row["Force Production"]),
          // acceleration: Number(row.Acceleration),
          power: Number(row.Power),
          speed: convertSecondsToMPH(Number(row.Speed)),
        };
      });

      const checkedData: { success: boolean; unauthorizedIDs: number[] } =
        await athleteApi.checkAthleteImport(dataReady);

      if (checkedData.success) {
        setShowModal(false);
        navigate("/athletes/edit-import", { state: { data: dataReady } });
      } else {
        setError(
          `There are athlete IDs in this CSV that do not belong to your gym: ${checkedData.unauthorizedIDs.join(
            ", "
          )}. Please fix and attempt to upload again.`
        );
      }
      console.log(checkedData);
    };
    if (csvFile.type === "text/csv") {
      reader.readAsText(csvFile);
    } else {
      reader.readAsBinaryString(csvFile);
    }
    setIsImporting(false);
  };

  return (
    <>
      <AnimatePresence>
        <div className="mb-4">
          <input
            type="file"
            className="file-input file-input-bordered w-full max-w-xs"
            onChange={handleChangeFile}
          />
          {error && (
            <motion.p
              initial={{ opacity: 0, scale: 0.5 }}
              transition={{
                opacity: { duration: 0.1 },
                scale: { duration: 0.1 },
              }}
              animate={{ opacity: 1, scale: 1 }}
              exit={{ opacity: 0, scale: 0.5 }}
              className="text-error font-medium my-2"
            >
              {error}
            </motion.p>
          )}
          {csvFile && !error && (
            <div className="mt-4 -mb-4">
              <ModalButton
                isLoading={isImporting}
                onSubmit={readFile}
                label={"Import"}
                loadingLabel="Importing"
                icon={faFileLines}
              />
            </div>
          )}
        </div>
      </AnimatePresence>
    </>
  );
};

export default ImportDataModal;
