import axios from "src/utils/axios";
import ApiTokenSupport from "./token.support";
import Metric from "src/interfaces/metric.interface";
import MetricType from "src/interfaces/metricType.interface";

class MetricApi extends ApiTokenSupport {
  async getByAthleteID(
    athleteID: number,
    exerciseID: number
  ): Promise<Metric[]> {
    const resp = await axios.get<Metric[]>(
      `api/metric/${athleteID}/${exerciseID}`,
      this.withAuthorization()
    );
    return resp.data;
  }

  async create(data: {
    metric: Metric;
    metricType: MetricType;
  }): Promise<Metric> {
    const resp = await axios.post<Metric>(
      `api/metric`,
      data,
      this.withAuthorization()
    );
    return resp.data;
  }

  async delete(metric: Metric): Promise<Metric> {
    const resp = await axios.delete<Metric>(
      `api/metric/${metric.id}`,
      this.withAuthorization()
    );
    return resp.data;
  }
}

export default new MetricApi();
