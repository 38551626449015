import React, {
  createContext,
  ReactNode,
  useCallback,
  useEffect,
  useReducer,
} from "react";
import axios from "src/utils/axios";
import jwt_decode from "jwt-decode";
import userApi from "src/api/user";
import useAlert from "src/hooks/useAlert";

const TOKEN_ID = process.env.REACT_APP_AUTH_HEADER || "";

interface AuthState {
  currentUser: number | null;
  redirectTo?: string;
}

interface AuthProviderProps {
  children: ReactNode;
}

interface AuthContextValue extends AuthState {
  login: (email: string, password: string) => Promise<void>;
  logout: () => void;
  register: (email: string, password: string) => Promise<void>;
  setRedirection: (url: string) => void;
}

const initialAuthState: AuthState = {
  currentUser: null,
};

type InitializeAction = {
  type: "INITIALIZE";
  payload: {
    currentUser: number | null;
  };
};

type LoginAction = {
  type: "LOGIN";
  payload: {
    currentUser: number | null;
  };
};

type LogoutAction = {
  type: "LOGOUT";
  payload?: {};
};

type RegisterAction = {
  type: "REGISTER";
  payload: {
    currentUser: number | null;
  };
};

type RedirectAction = {
  type: "REDIRECT";
  payload: string;
};

type Action =
  | InitializeAction
  | LoginAction
  | LogoutAction
  | RegisterAction
  | RedirectAction;

const setSession = (token: string | null | any): void => {
  if (token) {
    axios.defaults.headers.common.Authorization = `Bearer ${token}`;
    localStorage.setItem(TOKEN_ID, token);
  } else {
    localStorage.setItem(TOKEN_ID, "");
    delete axios.defaults.headers.common.Authorization;
  }
};

const decodeToken = (token: any) => {
  if(!token) return null;
  if (typeof token === "string") {
    const decoded: any = jwt_decode(token);
    const expireAt = new Date(decoded.exp * 1000);
    const currentTime = new Date();
    if (expireAt > currentTime) {
      return decoded.user.id;
    }
  } else if (typeof token.token === "string") {
    const decoded: any = jwt_decode(token.token);
    const expireAt = new Date(decoded.exp * 1000);
    const currentTime = new Date();
    if (expireAt > currentTime) {
      return decoded.user.id;
    }
  }

  return null;
};

const handlers = {
  INITIALIZE: (state: AuthState, action: InitializeAction): AuthState => {
    const { currentUser } = action.payload;
    return {
      ...state,
      currentUser,
    };
  },
  LOGIN: (state: AuthState, action: LoginAction) => {
    const { currentUser } = action.payload;
    return {
      ...state,
      currentUser,
    };
  },
  LOGOUT: (state: AuthState) => ({
    ...state,
    currentUser: null,
  }),
  REGISTER: (state: AuthState, action: RegisterAction) => {
    const { currentUser } = action.payload;
    return {
      ...state,
      currentUser,
    };
  },
  REDIRECT: (state: AuthState, action: RedirectAction) => {
    return {
      ...state,
      redirectTo: action.payload,
    };
  },
};

const reducer = (state: AuthState, action: Action): AuthState =>
  // @ts-ignore
  handlers[action.type] ? handlers[action.type](state, action) : state;

const AuthContext = createContext<AuthContextValue>({
  ...initialAuthState,
  login: () => Promise.resolve(),
  logout: () => Promise.resolve(),
  register: () => Promise.resolve(),
  setRedirection: () => {},
});

export const AuthProvider: React.FC<AuthProviderProps> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, initialAuthState);
  const { setAlert, clearAlert } = useAlert();

  useEffect(() => {
    const initialize = async () => {
      try {
        const token = localStorage.getItem(TOKEN_ID);

        const currentUser = decodeToken(token);

        if (currentUser) {
          dispatch({
            type: "INITIALIZE",
            payload: {
              currentUser,
            },
          });
        } else {
          setSession(null);
          dispatch({
            type: "INITIALIZE",
            payload: {
              currentUser: null,
            },
          });
        }
      } catch (err) {
        console.log(err);
        dispatch({
          type: "INITIALIZE",
          payload: {
            currentUser: null,
          },
        });
      }
    };

    initialize();
  }, []);

  const login = async (email: string, password: string) => {
    try {
      const token: any = await userApi.login(email, password);
      const currentUser = decodeToken(token);
      setSession(token.token);

      dispatch({
        type: "LOGIN",
        payload: {
          currentUser,
        },
      });

      clearAlert();
    } catch (err: any) {
      console.error("App login: problem logging", err);
      setAlert({
        display: true,
        message: err?.message || "Unable to login",
        type: "error",
      });
    }
  };

  const logout = async () => {
    setSession(null);
    dispatch({ type: "LOGOUT" });
  };

  const register = async (email: string, password: string) => {
    try {
      const token: any = await userApi.register(email, password);
      const currentUser = decodeToken(token);

      setSession(token.token);

      dispatch({
        type: "REGISTER",
        payload: {
          currentUser,
        },
      });

      clearAlert();
    } catch (err: any) {
      console.error("App register: problem registering", err);
      setAlert({
        display: true,
        message: err?.message || "Unable to register",
        type: "error",
      });
    }
  };

  const setRedirection = useCallback((url: string) => {
    dispatch({
      type: "REDIRECT",
      payload: url,
    });
  }, []);

  return (
    <AuthContext.Provider
      value={{
        ...state,
        login,
        logout,
        register,
        setRedirection,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export default AuthContext;
