import { AnimatePresence } from "framer-motion";
import React, { useState } from "react";
import { Navigate, useLocation, useNavigate } from "react-router-dom";
import { athleteApi } from "src/api";
import ImportNameRow from "src/components/athletes/ImportNameRow";
import useAthletesContext from "src/hooks/private/useAthletesContext";

const AthletesImportNames = () => {
  const location = useLocation();
  const data: { Name: string }[] = location.state?.data;

  const navigate = useNavigate();

  const { fetchAthletes } = useAthletesContext();

  const [athletes, setAthletes] = useState<{ Name: string }[]>(data);

  if (!data) {
    return <Navigate to={"/athletes"} />;
  }

  const updateAthlete = (index: number, updatedAthlete: { Name: string }) => {
    setAthletes((prevAthletes) =>
      prevAthletes.map((athlete, idx) =>
        idx === index ? updatedAthlete : athlete
      )
    );
  };

  const deleteAthlete = (index: number) => {
    setAthletes((prevAthletes) =>
      prevAthletes.filter((_, idx) => idx !== index)
    );
  };

  const handleAddToDatabase = async () => {
    try {
      await athleteApi.importAthletes(athletes);
    } catch (e: any) {
      console.log(e);
    } finally {
      fetchAthletes();
      navigate("/athletes");
    }
  };

  return (
    <>
      <div>
        <div className="w-full flex items-center justify-between my-6 gap-12">
          <div>
            <h1 className="text-lg">Edit any data before adding to Database</h1>
          </div>
          <div>
            <button
              className="normal-case bg-back-light border-text-nonfocus border rounded-md px-1 h-7 text-sm btn btn-xs hover:bg-back-light border-1 shadow-sm text-text-light"
              onClick={handleAddToDatabase}
            >
              + Add To Database
            </button>
          </div>
        </div>
        <div className="overflow-x-auto flex items-center justify-center">
          <table className="table">
            <thead>
              <tr>
                <th></th>
                <th>Name</th>
              </tr>
            </thead>
            <tbody>
              <AnimatePresence mode="sync">
                {athletes.map((athlete, index) => (
                  <ImportNameRow
                    key={index}
                    athlete={athlete}
                    onUpdate={(updatedAthlete) =>
                      updateAthlete(index, updatedAthlete)
                    }
                    onDelete={() => deleteAthlete(index)}
                  />
                ))}
              </AnimatePresence>
            </tbody>
          </table>
        </div>
      </div>
    </>
  );
};

export default AthletesImportNames;
