import React, { useState } from "react";
import { motion } from "framer-motion";
import parseMonthDayYear from "src/helpers/parseMonthDayYear";
import Metric from "src/interfaces/metric.interface";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrashCan } from "@fortawesome/free-regular-svg-icons";
import { metricApi } from "src/api";
import useAlert from "src/hooks/useAlert";
import { exerciseIDs } from "src/global/data/exercises";
import useUserContext from "src/hooks/private/useUserContext";
import { metricConversionMap } from "src/utils/conversionFunctions";

interface MetricRowProps {
  metric: Metric;
  fetchMetrics: () => void;
}

const MetricRow = ({ metric, fetchMetrics }: MetricRowProps) => {
  const { id, date, value } = metric;
  const { setAlert } = useAlert();
  const { gymMetricPreferences, metricTypes } = useUserContext();

  const [isHoveringRow, setIsHoveringRow] = useState<boolean>(false);

  const convertMetric = (metric: Metric): { value: number; unit: string } => {
    const preference = gymMetricPreferences?.find(
      (pref) => pref.exercise_id === metric.exercise_id
    );
    const metricType = metricTypes?.find(
      (type) => type.id === preference?.preferred_metric_type_id
    );

    if (metricType) {
      // Check if there's a conversion function for the given metric_type_id
      const conversion = metricConversionMap[metricType.id];
      if (conversion) {
        return {
          value: Number(conversion.fromMPH(metric.value).toFixed(2)),
          unit: metricType.unit,
        };
      }

      // No conversion needed
      return { value: metric.value, unit: metricType.unit || "" };
    }

    // Fallback if no metric type is found
    return { value: metric.value, unit: "" };
  };

  const convertedMetric = convertMetric(metric);

  const handleDelete = async () => {
    try {
      const res = await metricApi.delete(metric);
    } catch (e: any) {
      console.log(e);
      setAlert({
        display: true,
        type: "error",
        message: e.message || "An error has occurred",
      });
    } finally {
      fetchMetrics();
      setAlert({
        display: true,
        type: "info",
        message: `${
          exerciseIDs[metric.exercise_id]
        } metric on ${parseMonthDayYear(metric.date)} was successfully deleted`,
      });
    }
  };

  return (
    <>
      <motion.tr
        layout
        key={id}
        className="cursor-pointer border-b-1"
        initial={{ opacity: 0 }}
        animate={{ opacity: 1 }}
        transition={{
          opacity: { duration: 0.25 },
        }}
        exit={{ opacity: 0 }}
        onHoverStart={() => setIsHoveringRow(true)}
        onHoverEnd={() => setIsHoveringRow(false)}
      >
        <td className="py-0 my-0">
          <button
            className={
              isHoveringRow
                ? "btn btn-square btn-ghost h-6 w-6 px-6 text-black hover:text-error"
                : "invisible h-6 w-6 px-6"
            }
            onClick={handleDelete}
          >
            <FontAwesomeIcon
              icon={faTrashCan}
              className={isHoveringRow ? "" : "invisible"}
            />
          </button>
        </td>
        <td className="font-medium text-black">
          {`${parseMonthDayYear(date)}`}
        </td>
        <td className="text-text-light">
          {convertedMetric.value} {convertedMetric.unit}
        </td>
      </motion.tr>
    </>
  );
};

export default MetricRow;