import React from "react";
import Metric from "src/interfaces/metric.interface";
import { AnimatePresence } from "framer-motion";
import MetricRow from "./MetricRow";
import { exerciseIDs } from "src/global/data/exercises";

interface ListTableProps {
  metrics: Metric[];
  unit: string;
  fetchMetrics: () => void;
}

const ListTable = ({ metrics, unit, fetchMetrics }: ListTableProps) => {
  return (
    <>
      {metrics && metrics.length ? (
        <div className="overflow-x-auto overflow-y-auto overscroll-y-none h-full">
          <table className="table relative overscroll-y-none table-fixed">
            <thead className="sticky top-0">
              <tr className="border-b-2 bg-back-light sticky">
                <th className="normal-case bg-back-light sticky"></th>
                <th className="normal-case bg-back-light sticky">Date</th>
                <th className="normal-case bg-back-light sticky">
                  {exerciseIDs[metrics[0].exercise_id]}
                  <span className="font-normal"> ({unit})</span>
                </th>
                {/* Add more headers if needed */}
              </tr>
            </thead>
            <tbody className="border-b-2">
              <AnimatePresence mode="sync">
                {metrics.map((metric: Metric, index: number) => (
                  <MetricRow
                    metric={metric}
                    fetchMetrics={fetchMetrics}
                    key={index}
                  />
                ))}
              </AnimatePresence>
            </tbody>
          </table>
        </div>
      ) : (
        <>
          <div>
            <p>No metrics for this exercise yet.</p>
          </div>
        </>
      )}
    </>
  );
};

export default ListTable;
