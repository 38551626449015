export default function capitalize(str: any) {
  if(str === undefined || str === '' || typeof str !== 'string' || str === ' ' ) return '';
  if (typeof str === "string") {
    let arr = str.split("");
    arr[0] = arr[0].toUpperCase();
    return arr.join("");
  } else {
    return "";
  }
}

export { capitalize };