import axios from "src/utils/axios";
import ApiTokenSupport from "./token.support";
import { Athlete, AthletePlusMetric } from "src/interfaces/athlete.interface";
import AthleteCSVRow from "src/interfaces/athleteCSVRow.interface";

class AthleteApi extends ApiTokenSupport {
  async get(athleteID: number): Promise<Athlete> {
    const resp = await axios.get<Athlete>(
      `/api/athlete/${athleteID}`,
      this.withAuthorization()
    );
    return resp.data;
  }

  async getAll(): Promise<AthletePlusMetric[]> {
    const resp = await axios.get<AthletePlusMetric[]>(
      "/api/athlete/list",
      this.withAuthorization()
    );
    return resp.data;
  }

  async update(athlete: Athlete): Promise<Athlete> {
    const resp = await axios.put<Athlete>(
      `/api/athlete/${athlete.id}`,
      athlete,
      this.withAuthorization()
    );
    return resp.data;
  }

  async checkAthleteImport(
    data: AthleteCSVRow[]
  ): Promise<{ success: boolean; unauthorizedIDs: number[] }> {
    const resp = await axios.post<{
      success: boolean;
      unauthorizedIDs: number[];
    }>("/api/athlete/check-athlete-import", data, this.withAuthorization());
    return resp.data;
  }

  async import(data: AthleteCSVRow[]): Promise<any> {
    const resp = await axios.post<any>(
      "/api/athlete/import",
      data,
      this.withAuthorization()
    );
    return resp.data;
  }

  async importAthletes(data: { Name: string }[]): Promise<any> {
    const resp = await axios.post<any>(
      "/api/athlete/import-athletes",
      data,
      this.withAuthorization()
    );
    return resp.data;
  }

  async delete(athleteID: number): Promise<any> {
    const resp = await axios.delete<any>(
      `/api/athlete/${athleteID}`,
      this.withAuthorization()
    );
    return resp.data;
  }

  async create(athleteName: string): Promise<Athlete> {
    const resp = await axios.post<Athlete>(
      "/api/athlete",
      { name: athleteName },
      this.withAuthorization()
    );
    return resp.data;
  }
}

export default new AthleteApi();
