import { faFloppyDisk } from "@fortawesome/free-solid-svg-icons";
import React, { useEffect, useRef, useState } from "react";
import { gymApi, userApi } from "src/api";
import gym from "src/api/gym";
import useModal from "src/hooks/private/useModal";
import useAlert from "src/hooks/useAlert";
import { Trainer } from "src/interfaces/user.interface";
import ModalButton from "../buttons/ModalButton";
import FloatingInput from "../fields/FloatingInput";

interface EditTrainerModalProps {
  trainer: Trainer;
  fetchTrainers: () => void;
}

const EditTrainerModal = ({
  trainer,
  fetchTrainers,
}: EditTrainerModalProps) => {
  const inputRef = useRef<HTMLInputElement>(null);

  const { setShowModal } = useModal();

  const { setAlert } = useAlert();

  const [trainerName, setTrainerName] = useState<string>(trainer.name);

  const [isSaving, setIsSaving] = useState<boolean>(false);

  const initialFormErrors: FormErrors = {
    name: "",
  };

  interface FormErrors {
    name: string;
  }

  const [formErrors, setFormErrors] = useState<FormErrors>(initialFormErrors);

  const validate: any = (name: string) => {
    const errors: {
      name?: string;
    } = {};
    if (!name.trim()) {
      errors.name = "Trainer name is required";
      return errors;
    }
  };

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
  }, []);

  const handleSaveSubmit = async () => {
    let errors = validate(trainerName);
    if (errors?.name) {
      return setFormErrors(errors);
    } else {
      setFormErrors(initialFormErrors);
      try {
        setIsSaving(true);
        await userApi.updateUser({
          id: trainer.id,
          key: "name",
          value: trainerName,
        });
      } catch (e: any) {
        console.log(e);
        setAlert({
          display: true,
          type: "error",
          message: e.message || "An error has occurred",
        });
      } finally {
        fetchTrainers();
        setIsSaving(false);
        setShowModal(false);
        setAlert({
          display: true,
          type: "info",
          message: `${trainerName} was successfully updated`,
        });
      }
    }
  };

  const handleKeyDown = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      handleSaveSubmit();
    }
  };

  return (
    <>
      <div className="py-4 pb-6">
        <FloatingInput
          ref={inputRef}
          value={trainerName}
          onChange={(e: any) => setTrainerName(e.target.value)}
          label="Location Name"
          name="customField"
          handleKeyDown={handleKeyDown}
          error={formErrors.name}
        />
        <div className="mt-4 -mb-4">
          <ModalButton
            isLoading={isSaving}
            onSubmit={handleSaveSubmit}
            label={"Save"}
            loadingLabel="Saving"
            icon={faFloppyDisk}
          />
        </div>
      </div>
    </>
  );
};
export default EditTrainerModal;
