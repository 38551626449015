import React from "react";
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  ChartData,
  PointElement,
  LineElement,
  TimeScale,
} from "chart.js";
import { Bar } from "react-chartjs-2";
import "chartjs-adapter-moment";
import { TimeUnit } from "./Progress";
// import ChartDataLabels from 'chartjs-plugin-datalabels'; // Import the plugin


ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement,
  TimeScale,
  // ChartDataLabels,
);

interface PDFChartProps {
  chartData: ChartData<"bar", { x: Date | string; y: number }[], unknown>;
  title: string;
  unit: string;
  timeUnit: TimeUnit;
  invertChart?: boolean;
}

const GymPDFChart = ({
  chartData,
  title,
  unit,
  timeUnit,
  invertChart = false,
}: PDFChartProps) => {
  const chartOptions = {
    responsive: true,
    plugins: {
      legend: {
        display: false,
      },
      title: {
        display: true,
        text: `Gym ${title}`,
        color: "#163E63",
        padding: 8,
        font: {
          size: 18,
          family: "Arial",
        },
      },
      elements: {
        line: {
          tension: 0.3, // Adjust the tension to create smoother curves
        },
        point: {
          radius: 5, // Adjust the radius of the points
          hoverRadius: 7, // Adjust the hover radius of the points
          // Specify styles for different types of points if needed
        },
      },
      // datalabels: {
      //   display: true,
      //   align: 'end' as const, // Correct type
      //   anchor: 'end' as const, // Correct type
      //   // formatter: (value: number) => value.toFixed(2),
      //   color: '#333',
      //   font: {
      //     size: 10,
      //     weight: 'bold' as 'bold', // Correct type
      //   }
      // },
      tooltip: {
        enabled: true,
        mode: "index" as const,
        intersect: false,
        callbacks: {
          title: function (tooltipItems: any) {
            // Assuming the first item in the array represents the needed information
            if (tooltipItems.length) {
              const item = tooltipItems[0];
              // You need to ensure that the label is a string
              // If the label is not a string, you may need to convert it
              return item.label;
            }
            return "";
          },
          label: function (context: any) {
            // Format the label for the tooltip
            let label = context.dataset.label || "";
            if (label) {
              label += ": ";
            }
            if (context.parsed.y !== null) {
              label += context.parsed.y + " " + unit; // Customize unit as needed
            }
            // Add any additional information you want to show here
            return label;
          },
        },
      },
    },
    scales: {
      x: {
        type: "time" as "time",
        time: {
          unit: timeUnit as TimeUnit,
          tooltipFormat: "ll",
        },
        grid: {
          display: false,
        },
        ticks: {
          color: "#333",
          maxTicksLimit: 10,
        },
      },
      y: {
        reverse: invertChart,
        grid: {
          display: true,
          color: "#ddd",
        },
        title: {},
        ticks: {
          color: "#333",
        },
      },
    },
  };

  return (
    <>
      <Bar data={chartData} options={chartOptions} />
    </>
  );
};

export default GymPDFChart;
