import React from "react";
import {
  faEllipsis,
  faPaperPlane,
  faPenToSquare,
  faTrashCan,
} from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Trainer } from "src/interfaces/user.interface";
import DeleteTrainerModal from "../modals/DeleteTrainerModal";
import useModal from "src/hooks/private/useModal";
import useAlert from "src/hooks/useAlert";
import { userApi } from "src/api";
import EditTrainerModal from "../modals/EditTrainerModal";

interface TrainerActionsButtonProps {
  trainer: Trainer;
  fetchGyms: () => {};
  fetchTrainers: () => {};
}

const TrainerActionsButton = ({
  trainer,
  fetchGyms,
  fetchTrainers,
}: TrainerActionsButtonProps) => {
  const { openModalWith } = useModal();

  const { setAlert } = useAlert();

  const handleResendInvite = async () => {
    try {
      const res = await userApi.resendInvite(trainer);
      if (res) {
        setAlert({
          display: true,
          type: "info",
          message: "Invite successfully resent",
        });
      }
    } catch (e: any) {
      setAlert({
        display: true,
        type: "error",
        message: e.message || "An error has occurred",
      });
    }
  };

  const handleEditTrainer = () => {
    openModalWith({
      title: "",
      body: (
        <EditTrainerModal trainer={trainer} fetchTrainers={fetchTrainers} />
      ),
      showButtons: false,
    });
  };

  const handleDeleteTrainer = () => {
    openModalWith({
      title: "",
      body: (
        <DeleteTrainerModal
          trainer={trainer}
          fetchGyms={fetchGyms}
          fetchTrainers={fetchTrainers}
        />
      ),
      showButtons: false,
    });
  };

  return (
    <>
      <div className="dropdown dropdown-end  ">
        <label
          tabIndex={0}
          className="btn m-1 normal-case bg-back-light border-text-nonfocus border rounded-md px-3 h-7 text-sm btn-xs hover:bg-back-light border-1 shadow-sm text-text-light"
        >
          <FontAwesomeIcon icon={faEllipsis} className="" />
        </label>
        <ul
          tabIndex={0}
          className="dropdown-content z-[999] menu p-2 shadow bg-base-100 rounded-box w-52"
        >
          <li>
            <button onClick={handleEditTrainer}>
              <FontAwesomeIcon icon={faPenToSquare} />
              Edit Trainer
            </button>
          </li>

          {!trainer.accepted_invite && (
            <li>
              <button onClick={handleResendInvite}>
                <FontAwesomeIcon icon={faPaperPlane} />
                Resend Invite
              </button>
            </li>
          )}
          {trainer.id !== 1 && (
            <li>
              <button className="text-error" onClick={handleDeleteTrainer}>
                <FontAwesomeIcon icon={faTrashCan} />
                Delete Trainer
              </button>
            </li>
          )}
        </ul>
      </div>
    </>
  );
};

export default TrainerActionsButton;
