import React from "react";
import { Link } from "react-router-dom";

const InvalidToken = () => {
  return (
    <>
      <div className="fixed w-full h-full flex justify-center bg-back-light">
        <div className="flex flex-col columns-1 items-center text-primary">
          <h1 className="text-[96px]">Oops!</h1>
          <h2 className="text-[36px]">404 - Invalid Token</h2>
          <div className="w-1/2 text-center mb-2">
            The token you are trying to use has either been used or is expired.
          </div>
          <div>
            <Link to="/">
              <button className="btn btn-sm text-white btn-primary">
                Go Home
              </button>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default InvalidToken;
