import { PropsWithChildren } from "react";
import { ModalProvider } from "src/contexts/ModalContext";

/***** Context Provider *****/
import { UserProvider } from "src/contexts/private/UserContext";
import { AthleteProvider } from "src/contexts/private/AthletesContext";

interface PrivateProviderProps {}

const PrivateProvider = ({
  children,
}: PropsWithChildren<PrivateProviderProps>) => (
  <UserProvider>
    <AthleteProvider>
      <ModalProvider>{children}</ModalProvider>
    </AthleteProvider>
  </UserProvider>
);

export default PrivateProvider;
