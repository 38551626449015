import { useEffect, useState } from "react";
import useAuth from "src/hooks/useAuth";
import { regexEmail } from "src/helpers/constants";
import AuthButton from "src/components/buttons/AuthButton";
import Alert from "src/components/alert/Alert";
import useAlert from "src/hooks/useAlert";
import FloatingInput from "src/components/fields/FloatingInput";
import "./Login.css";
import { useNavigate } from "react-router-dom";
import { useLocation } from "react-router-dom";

const Login = () => {
  const { alert, clearAlert, setAlert } = useAlert();

  const location = useLocation();

  const { state } = location;

  const [loading, setIsLoading] = useState(true);

  useEffect(() => {
    if (state && state.displayAlert) {
      setTimeout(() => {
        setAlert({
          display: true,
          type: state.displayAlert.type,
          message: state.displayAlert.message,
        });
      }, 10);
    }
    setIsLoading(false);
  }, [setAlert, state]);

  const navigate = useNavigate();

  const initialFormState = {
    email: "",
    password: "",
  };

  const initialFormErrors: {
    email: string;
    password: string;
  } = {
    email: "",
    password: "",
  };

  interface FormErrors {
    email: string | null;
    password: string | null;
  }

  const [formData, setFormData] = useState(initialFormState);
  const [submitting, setSubmitting] = useState<boolean>(false);
  const [formErrors, setFormErrors] = useState<FormErrors>(initialFormErrors);

  const { login } = useAuth();

  const handleChange = (e: any) => {
    const { name, value } = e.target;
    setFormData({ ...formData, [name]: value });
  };

  const validate: any = (data: any) => {
    const errors: {
      email?: string;
      password?: string;
    } = {};
    if (!data.email) {
      errors.email = "Email is required";
    } else if (!regexEmail.test(data.email)) {
      errors.email = "Email is not a valid format";
    }
    if (!data.password) {
      errors.password = "Password is required";
    }
    return errors;
  };

  async function handleSubmit(e: any) {
    e.preventDefault();
    let errors = validate(formData);
    if (errors.email || errors.password) {
      return setFormErrors(errors);
    } else {
      const { email, password } = formData;
      setSubmitting(true);
      await login(email.toLowerCase(), password);
      setSubmitting(false);
    }
  }

  const handleForgotPassword = () => {
    navigate("/password-reset");
  };

  if (loading) {
    return null;
  }

  return (
    <>
      <div className="container mx-auto px-4">
        <div className="flex items-center justify-center ">
          <div className="w-full sm:w-8/12 md:w-7/12 lg:w-5/12 xl:w-4/12 2xl:w-[450px] px-2">
            <div className="relative flex flex-col min-w-0 break-words w-full mb-6 mt-28 shadow-lg rounded-lg bg-back-light group">
              <div className="absolute -inset-0.5 bg-gradient-to-tr from-gradientBlue to-gradientDarkBlue bg-error rounded-lg blur-lg opacity-75 group-hover:opacity-100 transition duration-1000 group-hover:duration-200 animate-tilt"></div>
              <div className="relative flex-auto px-4 lg:px-10 py-10 pt-10 bg-back-light rounded-2xl">
                <Alert alert={alert} onClose={clearAlert} />
                <form onSubmit={handleSubmit}>
                  <div className="relative w-full mb-3">
                    <FloatingInput
                      name="email"
                      label="Email"
                      value={formData.email}
                      onChange={handleChange}
                      error={formErrors.email}
                    />
                  </div>

                  <div className="relative w-full mb-3">
                    <FloatingInput
                      label="Password"
                      type="password"
                      name="password"
                      value={formData.password}
                      onChange={handleChange}
                      error={formErrors.password}
                    />
                  </div>
                  <div>
                    <label className="inline-flex items-center cursor-pointer">
                      <input
                        type="checkbox"
                        className="checkbox checkbox-primary"
                      />
                      <span className="ml-2 text-sm font-semibold text-neutral-500">
                        Remember Me
                      </span>
                    </label>
                  </div>

                  <div className="text-center mt-6">
                    <AuthButton className="" loading={submitting} type="submit">
                      Login
                    </AuthButton>
                  </div>
                </form>
              </div>
            </div>
            <div className="w-full flex items-center justify-center">
              <p
                className="hover:underline text-sm text-primary font-medium cursor-pointer"
                onClick={handleForgotPassword}
              >
                Forgot Password
              </p>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Login;
